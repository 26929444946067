import React, { useCallback, useContext, useState } from 'react'
import Pagination from 'react-js-pagination'
import AppContext from '../../../../../../src/common/context/context'
import Size from '../../../../../../src/common/utils/media-queries-setup'
import DropDown from '../../../../../../src/common/components/dropDown/DropDown'
import NeumaticoMotoResultsCard from '../../../neumaticoMotoResultsCard/NeumaticoMotoResultsCard'
import NoResultsMessage from '../../../../../../src/common/components/noResultsMessage/NoResultsMessage'
import SpinnerWithText from '../../../../../../src/common/components/spinnerWithText/SpinnerWithText'
import styles from '../searchResults.module.scss'
import DBFilters from './DBFilters'
import MobilePagination from '../../Common/MobilePagination'
import { SearchMotoState } from '../../../../context/reducers/searchMotoReducer'
import {
  OrderEntry,
  ORDER_OPTIONS,
} from '../../../../context/actions/searchActions'
import {
  DEFAULT_PAGE_SIZE,
  searchMoto,
} from '../../../../context/actions/searchMotoActions'
import { t } from '../../../../i18n'
import { NeumaticoMotoResult } from '../../../../api/searchMoto'

interface Props {
  searchMotoState: SearchMotoState
  onSelectNeumatico: (neumatico: NeumaticoMotoResult) => void
}

const DBResults = ({ searchMotoState, onSelectNeumatico }: Props) => {
  const { dispatch, selectorMotoState } = useContext(AppContext)
  const [order, setOrder] = useState(ORDER_OPTIONS[0])
  const neumaticos = searchMotoState.results.neumaticos

  // funcion para buscar los neumaticos cuando se selecciona una pagina nueva
  // del pagindador o algun selector de orden
  const callSearchNeumaticos = useCallback(
    (pageNumber: number, order: OrderEntry) => {
      dispatch(
        searchMoto({
          pagination: {
            pageSize: DEFAULT_PAGE_SIZE,
            page: pageNumber,
          },
          order: order.field,
          direction: order.direction,
        })
      )
    },
    [selectorMotoState, dispatch]
  )

  const handlePageChange = (page: number) => {
    callSearchNeumaticos(page - 1, order)
  }

  const changeOrder = (event) => {
    event.preventDefault()
    const value = event.target.value
    const order = ORDER_OPTIONS.find((o) => o.value === value)
    setOrder(order)
    callSearchNeumaticos(0, order)
  }

  return (
    <section className={styles.tyresSearcDBFilter_wrapper}>
      <DBFilters />

      <div className={styles.order_by_wrapper}>
        <div>
          <span className={styles.mostrando_text}>
            {t('resultados_neumaticos.mostrando')}
          </span>
          {`${searchMotoState.results.neumaticos.length} de ${
            searchMotoState.results.total
          } ${t('menuHeader.neumaticos')}`}
        </div>
        <div className={styles.order}>
          <span className={styles.order_text}>
            {t('resultados_neumaticos.ordenar_por')}:{' '}
          </span>
          <DropDown
            name="orderBy"
            onChange={changeOrder}
            options={ORDER_OPTIONS}
          />
        </div>
      </div>

      {searchMotoState.loading ? (
        <SpinnerWithText
          text={t('selector.buscando_neumaticos')}
          className={styles.loading_container}
        />
      ) : (
        <React.Fragment>
          <div className={styles.neumaticos_cards_wrapper}>
            {neumaticos &&
              neumaticos.map((neumatico, index) => (
                <NeumaticoMotoResultsCard
                  onSelectNeumatico={onSelectNeumatico}
                  key={index}
                  neumatico={neumatico}
                  selectorMotoState={selectorMotoState}
                />
              ))}
          </div>
          {neumaticos && !neumaticos.length && <NoResultsMessage />}
        </React.Fragment>
      )}

      {neumaticos && neumaticos.length > 0 ? (
        <Size.Matcher
          desktop={
            <Pagination
              activePage={searchMotoState.query.pagination.page + 1}
              itemsCountPerPage={DEFAULT_PAGE_SIZE}
              totalItemsCount={searchMotoState.results.total}
              itemClass="item"
              hideDisabled
              hideFirstLastPages
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              prevPageText={`< ${t('resultados_neumaticos.anterior')}`}
              nextPageText={`${t('resultados_neumaticos.siguiente')} >`}
            />
          }
          mobile={
            <MobilePagination
              handlePageChange={handlePageChange}
              currentPage={searchMotoState.query.pagination.page + 1}
              totalPages={searchMotoState.results.pages}
              totalItems={searchMotoState.results.total}
            />
          }
        />
      ) : null}
    </section>
  )
}

export default DBResults
