import React from 'react'
import { NeumaticoMotoResult } from '../../api/searchMoto'
import { intl, t } from '../../i18n'
import Button from '../../../../src/common/components/buttons/Button'
import { isPromocionForProductos } from '../../context/selectors/cestaSelectors'
import { SelectorMotoState } from '../../context/reducers/selectorMotoReducer'
import { capitalize } from 'lodash'
import styles from './neumaticoMotoResultsCard.module.scss'
import { PromotionBanner } from '../promotionBanner/PromotionBanner'

interface LogoProps {
  slug: string | null
}

interface PriceProps {
  neumatico: NeumaticoMotoResult
}

const Logo = ({ slug }: LogoProps) => {
  let Image
  try {
    Image = require(`../../../../src/common/assets/images/logo_marcas/${slug}.svg`)
  } catch (e) {
    Image = require(`../../../../src/common/assets/images/logo_marcas/no-marca.svg`)
  }
  return <Image className={styles.marca}></Image>
}

const templateLiteral = (neumatico: NeumaticoMotoResult) => {
  return (
    <>
      {neumatico.ancho && `${neumatico.ancho}`}
      {'/'}
      {neumatico.alto && `${neumatico.alto} `}
      {neumatico.radial && `${neumatico.radial}`}
      {`${neumatico.llanta} ${neumatico.serie}`}
      {neumatico.normativa && ` ${neumatico.normativa}`}
      {(neumatico.flanco ||
        neumatico.marcaje ||
        neumatico.compuesto ||
        neumatico.terreno) &&
        ' - '}
      {neumatico.flanco && `${neumatico.flanco} `}
      {neumatico.marcaje && `${neumatico.marcaje} `}
      {neumatico.compuesto && `${neumatico.compuesto} `}
      {neumatico.terreno && `${neumatico.terreno} `}
      <div>{neumatico.homologacion && `${neumatico.homologacion}`}</div>
    </>
  )
}

const Prices = ({ neumatico }: PriceProps) => {
  let crossedPrice
  if (
    (neumatico.promo && neumatico.promo !== '0.000') ||
    (neumatico.promocionCesta &&
      neumatico.promocionCesta.importePromocion &&
      isPromocionForProductos(neumatico.promocionCesta))
  ) {
    crossedPrice = (
      <div className={styles.priceSinPromo}>
        <del>
          {intl.formatNumber(neumatico.precioSinPromo, {
            style: 'currency',
            currency: 'EUR',
          })}
        </del>
      </div>
    )
  }

  return (
    <div className={styles.prices}>
      {crossedPrice}
      <div className={styles.price}>
        {neumatico.promocionCesta &&
        isPromocionForProductos(neumatico.promocionCesta)
          ? intl.formatNumber(
              neumatico.precio - neumatico.promocionCesta.importePromocion,
              {
                style: 'currency',
                currency: 'EUR',
              }
            )
          : intl.formatNumber(neumatico.precio, {
              style: 'currency',
              currency: 'EUR',
            })}
      </div>

      <div className={styles.ecotasa}>
        {neumatico.promocionCesta &&
        isPromocionForProductos(neumatico.promocionCesta)
          ? intl.formatNumber(
              neumatico.precioConEcotasa -
                neumatico.promocionCesta.importePromocion,
              {
                style: 'currency',
                currency: 'EUR',
              }
            )
          : intl.formatNumber(neumatico.precioConEcotasa, {
              style: 'currency',
              currency: 'EUR',
            })}{' '}
        {t('resultados_neumaticos.con_ecotasa')}
      </div>
    </div>
  )
}

const CardMobile = ({ neumatico, onSelectNeumatico }: Props) => {
  return (
    <div className={styles.neumatico_results_card_mobile}>
      <div className={styles.content_top}>
        <div className={styles.neumatico}>
          <div className={styles.logoMarcaMobile}>
            {<Logo slug={neumatico.logo} />}
          </div>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url(${
                neumatico.urlImagen ||
                'https://media.rodi.es/marca/default/neumaticos/recortado/imagen-neumatico.png'
              })`,
            }}
          />
        </div>
        <div className={styles.description}>
          <div className={styles.marca}>
            {neumatico.marca} {neumatico.modelo}
          </div>
          <div className={styles.characteristics}>
            {templateLiteral(neumatico)}
          </div>
          <div className={styles.aplicacion}>
            {neumatico.aplicacion && capitalize(`${neumatico.aplicacion}`)}
          </div>
        </div>
        <Prices neumatico={neumatico} />
      </div>

      <div className={styles.content_bottom}>
        <div className="promobanner">
          {neumatico.promocion && (
            <PromotionBanner promocion={neumatico.promocion} />
          )}
        </div>
        <Button
          buttonClass="rodi_button_mobile"
          onClick={() => onSelectNeumatico(neumatico)}>
          {t('resultados_neumaticos.elegir')}
        </Button>
      </div>
    </div>
  )
}

const CardDesktop = ({ neumatico, onSelectNeumatico }: Props) => {
  return (
    <div className={styles.neumatico_results_card}>
      <div className={styles.images}>
        <div className={styles.logoMarca}>{<Logo slug={neumatico.logo} />}</div>
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${
              neumatico.urlImagen ||
              'https://media.rodi.es/marca/default/neumaticos/recortado/imagen-neumatico.png'
            })`,
          }}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.marca}>
          {neumatico.marca} {neumatico.modelo}
        </div>
        <div className={styles.characteristics}>
          {templateLiteral(neumatico)}
        </div>
        <div className={styles.aplicacion}>
          {neumatico.aplicacion && capitalize(`${neumatico.aplicacion}`)}
        </div>
        {neumatico.promocion && (
          <PromotionBanner promocion={neumatico.promocion} />
        )}
      </div>
      <div className={styles.actions}>
        <Prices neumatico={neumatico} />
        <Button
          buttonClass="rodi_button"
          onClick={() => onSelectNeumatico(neumatico)}>
          {t('resultados_neumaticos.elegir')}
        </Button>
      </div>
    </div>
  )
}

interface Props {
  neumatico: NeumaticoMotoResult
  onSelectNeumatico: (neumatico: NeumaticoMotoResult) => void
  selectorMotoState: Partial<SelectorMotoState>
}
export default function NeumaticoMotoResultsCard({ ...props }: Props) {
  return (
    <React.Fragment>
      <CardMobile {...props} />
      <CardDesktop {...props} />
    </React.Fragment>
  )
}
