import React, { useContext } from 'react'
import {
  FunnelMotoScreenProps,
  StepsFeaturesInMobile,
  StepsInMobile,
  TOTAL_MOBILE_STEPS,
  TOTAL_MOBILE_STEPS_FEATURES,
} from '../SelectorMotoSteps'
import AppContext from '../../../../../src/common/context/context'
import LayoutSelectorMotoMobile from '../../../../../src/common/components/layouts/layoutSelectorMoto/LayoutSelectorMotoMobile'
import { t } from '../../../i18n'
import SelectorMarcasMobile from '../Mobile/SelectorMarcasMobile'
import {
  resetMarca,
  selectMarca,
} from '../../../context/actions/selectorMotoActions'

type Props = FunnelMotoScreenProps
const FormMarcas = ({ nextStep }: Props) => {
  const { selectorMotoState, dispatch } = useContext(AppContext)
  const onMarcaSelect = (marca: string) => {
    dispatch(selectMarca(parseInt(marca)))
    nextStep()
  }

  const onSelectAllMarcas = () => {
    dispatch(resetMarca())
    nextStep()
  }
  return (
    <LayoutSelectorMotoMobile
      title={t('preferences.cambio_neumaticos')}
      subTitle={t('ficha-marca.marcas')}
      stepActive={
        selectorMotoState.buscar_por_medidas
          ? StepsInMobile.SELECT_MARCAS
          : StepsFeaturesInMobile.SELECT_MARCAS
      }
      totalSteps={
        selectorMotoState.buscar_por_medidas
          ? TOTAL_MOBILE_STEPS
          : TOTAL_MOBILE_STEPS_FEATURES
      }>
      <SelectorMarcasMobile
        onMarcaSelect={onMarcaSelect}
        onSelectTodas={onSelectAllMarcas}
      />
    </LayoutSelectorMotoMobile>
  )
}

export default FormMarcas
