import { MessageValue } from 'react-intl'
import {
  isFeatureEnabled,
  FEATURE_FLAGS,
} from '../../../src/common/utils/featureFlag'

export enum MenuPrincipal {
  HOME,
  NEUMATICOS,
  REVISIONES,
  COCHE,
  MOTO,
  CAMION,
  AGRICOLA,
  OFERTAS,
  TALLERES,
}

export type Route = string | ({ path: string } & { [k: string]: MessageValue })

interface BaseEntry {
  id: number
  name: string
}
type SubSubmenuEntry = BaseEntry & SubmenuEntryRoute
interface SubmenuEntrySubmenu {
  submenu: SubSubmenuEntry[]
}
interface SubmenuEntryRoute {
  route: Route
}
type SubmenuDetails = SubmenuEntrySubmenu | SubmenuEntryRoute
export type SubmenuEntry = BaseEntry & SubmenuDetails

export type RootEntry = {
  id: MenuPrincipal
  name: string
  icon: string
  route: string
  text_mobile?: string
} & {
  submenu: SubmenuEntry[]
}
interface Menu {
  menu: RootEntry[]
}

const menu = {
  menu: [
    {
      id: MenuPrincipal.NEUMATICOS,
      name: 'menuHeader.neumaticos',
      icon: 'neumaticos',
      route: 'neumaticos.index',
      text_mobile: 'comprar_neumaticos',
      submenu: [
        {
          id: 1,
          name: 'menuHeader.tipo',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.verano',
              route: 'landing-verano',
            },
            {
              id: 2,
              name: 'menuHeader.invierno',
              route: 'landing-invierno',
            },
            {
              id: 3,
              name: 'menuHeader.estaciones',
              route: 'landing-allseason',
            },
            {
              id: 4,
              name: 'menuHeader.4x4',
              route: 'landing-4x4',
            },
            {
              id: 5,
              name: 'menuHeader.furgoneta',
              route: 'landing-furgoneta',
            },
            {
              id: 6,
              name: 'menuHeader.neumaticos_moto',
              route: 'landing-moto',
            },
            {
              id: 7,
              name: 'menuHeader.quad',
              route: 'landing-quad',
            },
          ],
        },
        {
          id: 2,
          name: 'menuHeader.marca',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.michelin',
              route: {
                path: 'neumaticos.marca',
                marca: 'michelin',
              },
            },
            {
              id: 2,
              name: 'menuHeader.pirelli',
              route: {
                path: 'neumaticos.marca',
                marca: 'pirelli',
              },
            },
            {
              id: 3,
              name: 'menuHeader.goodyear',
              route: {
                path: 'neumaticos.marca',
                marca: 'goodyear',
              },
            },
            {
              id: 4,
              name: 'menuHeader.dunlop',
              route: {
                path: 'neumaticos.marca',
                marca: 'dunlop',
              },
            },
            {
              id: 5,
              name: 'menuHeader.continental',
              route: {
                path: 'neumaticos.marca',
                marca: 'continental',
              },
            },
            {
              id: 6,
              name: 'menuHeader.falken',
              route: {
                path: 'neumaticos.marca',
                marca: 'falken',
              },
            },
            {
              id: 7,
              name: 'menuHeader.hankook',
              route: {
                path: 'neumaticos.marca',
                marca: 'hankook',
              },
            },
            {
              id: 8,
              name: 'menuHeader.todas',
              route: 'landing-allbrands',
            },
          ],
        },
        {
          id: 3,
          name: 'menuHeader.ayudamos',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.servicio_neumatico',
              route: 'neumaticos.servicios.index',
            },
            {
              id: 2,
              name: 'menuHeader.todo_neumatico',
              route: 'neumaticos.todo_sobre',
            },
            {
              id: 3,
              name: 'menuHeader.seguro_neumatico',
              route: 'neumaticos.seguro',
            },
          ],
        },
      ],
    },
    {
      id: MenuPrincipal.REVISIONES,
      name: 'menuHeader.revisiones',
      icon: 'revision',
      route: 'revisiones.index',
      submenu: [
        {
          id: 1,
          name: 'menuHeader.packs_mantenimiento',
          route: 'coche.index',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.revision_basic',
              route: 'revisiones.revision-basic',
            },
            {
              id: 2,
              name: 'menuHeader.revision_plus',
              route: 'revisiones.revision-plus',
            },
            {
              id: 3,
              name: 'menuHeader.revision_premium',
              route: 'revisiones.revision-premium',
            },
            {
              id: 4,
              name: 'menuHeader.revision_confort',
              route: 'revisiones.revision-confort',
            },
            {
              id: 5,
              name: 'menuHeader.todas_revisiones',
              route: 'revisiones.index',
            },
          ],
        },
        {
          id: 2,
          name: 'menuHeader.seguridad',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.revision_preitv',
              route: 'revisiones.revision-pre-itv',
            },
            {
              id: 2,
              name: 'menuHeader.test_seguridad',
              route: 'revisiones.revision-test',
            },
            {
              id: 3,
              name: 'menuHeader.desinfeccion_ozono',
              route: 'coche.desinfeccion_ozono',
            },
          ],
        },
        {
          id: 3,
          name: 'menuHeader.garantia',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.revision_oficial',
              route: 'revisiones.revision-oficial',
            },
          ],
        },
      ],
    },
    {
      id: MenuPrincipal.COCHE,
      name: 'menuHeader.coche',
      icon: 'coches',
      route: 'coche.index',
      submenu: [
        {
          id: 1,
          name: 'menuHeader.mecanica',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.frenos',
              route: 'coche.frenos',
            },
            {
              id: 2,
              name: 'menuHeader.amortiguadores',
              route: 'coche.amortiguadores',
            },
            {
              id: 3,
              name: 'menuHeader.correa_distribución',
              route: 'coche.correa',
            },
            {
              id: 4,
              name: 'menuHeader.mecanica_diagnosis',
              route: 'coche.mecanica',
            },
          ],
        },
        {
          id: 2,
          name: 'menuHeader.mantenimiento',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.filtros',
              route: 'coche.filtros',
            },
            {
              id: 2,
              name: 'menuHeader.baterías',
              route: 'coche.baterias',
            },
            {
              id: 3,
              name: 'menuHeader.neumáticos',
              route: 'neumaticos.index',
            },
            {
              id: 4,
              name: 'menuHeader.cambio_aceite',
              route: 'coche.cambio_aceite',
            },
            {
              id: 5,
              name: 'menuHeader.caja_cambios',
              route: 'coche.caja_cambios',
            },
          ],
        },
        {
          id: 3,
          name: 'menuHeader.Puesta_punto',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.escobillas',
              route: 'coche.escobillas',
            },
            {
              id: 2,
              name: 'menuHeader.liquido_refrigerante',
              route: 'coche.liquido',
            },
            {
              id: 3,
              name: 'menuHeader.aire_acondicionado',
              route: 'coche.carga',
            },
            {
              id: 4,
              name: 'menuHeader.desinfeccion_ozono',
              route: 'coche.desinfeccion_ozono',
            },
            {
              id: 5,
              name: 'menuHeader.accesorios_transporte',
              route: 'coche.accesorios_transporte',
            },
          ],
        },
      ],
    },
    {
      id: MenuPrincipal.MOTO,
      name: 'menuHeader.moto',
      icon: 'moto',
      route: 'moto.index',
      submenu: [
        {
          id: 1,
          name: 'menuHeader.mecanica',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.frenos',
              route: 'moto.frenos',
            },
            {
              id: 2,
              name: 'menuHeader.kits_transmisión',
              route: 'moto.kits',
            },
            {
              id: 3,
              name: 'menuHeader.embragues',
              route: 'moto.embragues',
            },
          ],
        },
        {
          id: 2,
          name: 'menuHeader.mantenimiento',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.filtros',
              route: 'moto.filtros',
            },
            {
              id: 2,
              name: 'menuHeader.baterias',
              route: 'moto.baterias',
            },
            {
              id: 3,
              name: 'menuHeader.neumaticos',
              route: 'landing-moto',
            },
            {
              id: 4,
              name: 'menuHeader.revisiones',
              route: 'moto.revisiones',
            },
          ],
        },
        {
          id: 3,
          name: 'menuHeader.eventos',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.rodiBook',
              route: 'moto.rodibook',
            },
          ],
        },
      ],
    },
    {
      id: MenuPrincipal.CAMION,
      name: 'menuHeader.camion',
      icon: 'camion',
      route: 'camion.index',
      submenu: [
        {
          id: 1,
          name: 'menuHeader.mecanica',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.c-revision',
              route: 'camion.revisiones',
            },
            {
              id: 2,
              name: 'menuHeader.c-frenos',
              route: 'camion.frenos',
            },
            {
              id: 3,
              name: 'menuHeader.c-suspension',
              route: 'camion.suspension',
            },
            {
              id: 4,
              name: 'menuHeader.c-ejes',
              route: 'camion.ejes',
            },
          ],
        },
        {
          id: 2,
          name: 'menuHeader.servicios',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.c-asistencia',
              route: 'camion.asistencia',
            },
            {
              id: 2,
              name: 'menuHeader.c-industria',
              route: 'camion.neumaticos',
            },
          ],
        },
        {
          id: 3,
          name: 'menuHeader.profesional',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.c-gestion',
              route: 'camion.gestion',
            },
            {
              id: 2,
              name: 'menuHeader.c-acceso',
              route: 'https://www.rodi.es/acceso-profesionales/',
            },
          ],
        },
      ],
    },
    {
      id: MenuPrincipal.AGRICOLA,
      name: 'menuHeader.agricola',
      route: 'agricola.index',
      icon: 'agricola',
    },
    {
      id: MenuPrincipal.OFERTAS,
      name: 'menuHeader.ofertas',
      icon: 'percent',
      route: 'promociones.defecto',
    },
    {
      id: MenuPrincipal.TALLERES,
      name: 'menuHeader.talleres',
      icon: 'wrench',
      route: 'talleres',
    },
  ],
} as Menu

if (isFeatureEnabled(FEATURE_FLAGS.NEUMATICOS_EV)) {
  const subMenuNeumaticosTipo = menu.menu[0].submenu[0] as SubmenuEntrySubmenu
  subMenuNeumaticosTipo.submenu.push({
    id: 9,
    name: 'menuHeader.coche_electrico',
    route: 'landing-coche-electrico',
  })
}
export const findMenuEntry = (id: MenuPrincipal) =>
  menu.menu.find((e) => e.id === id)

export default menu
