import styles from './index.module.scss'
import React, { useContext } from 'react'
import MarcasListMobile from './MarcasListMobile'
import AppContext from '../../../../../src/common/context/context'

interface Props {
  onMarcaSelect: (marca: string) => void
  onSelectTodas: () => void
}

const SelectorMarcasMobile = ({ onMarcaSelect, onSelectTodas }: Props) => {
  const { selectorMotoState } = useContext(AppContext)

  return (
    <div className={styles.ancho_wrapper}>
      <div className={styles.options_container}>
        <MarcasListMobile
          onSelectTodas={onSelectTodas}
          marcas={selectorMotoState.filterables.marcas}
          onMarcaSelect={onMarcaSelect}
        />
      </div>
    </div>
  )
}

export default SelectorMarcasMobile
