import { navigate } from 'gatsby'
import React, { useContext, useState } from 'react'
import NeumaticoHomeMoto from '../../../assets/home/neumatico_home_moto.svg'
import MotoIcon from '../../../assets/images/family_icons/icon_moto.svg'
import {
  buscarPorMedidas,
  buscarPorModelo,
} from '../../../context/actions/selectorMotoActions'

import ProgressBar from '../layoutSelector/Mobile/ProgressBar'
import AppContext from '../../../context/context'
import { t } from '../../../../../sites/rodi/i18n'
import Step, { StepDetails } from '../../FunnelSelectorMoto/SelectorMotoSteps'
import { dataSteps, LayoutSelectorMotoProps } from './LayoutSelectorMoto'
import styles from './LayoutSelectorMotoMobile.module.scss'
import SelectorMotoFooterMobile from '../../FunnelSelectorMoto/Footers/SelectorMotoFooterMobile'

const LayoutSelectorMotoMobile = ({
  title,
  subTitle = '',
  subTitle2 = '',
  stepActive,
  totalSteps = dataSteps.length,
  hideSelectorSteps = false,
  showFooterMobile = true,
  onClickButtonEditMobile,
  showButtonEdit = false,
  children,
  showTabs = true,
}: LayoutSelectorMotoProps) => {
  const { selectorMotoState, dispatch } = useContext(AppContext)

  const [tabActive] = useState(selectorMotoState.buscar_por_medidas ? 0 : 1)

  const onClickChangeTab = async (id) => {
    if (id === 0) {
      // cambiamos a buscar por medidas
      await buscarPorMedidas(selectorMotoState, dispatch)
      navigate(StepDetails[Step.SELECT_ANCHO].routes.mobile)
    } else if (id === 1) {
      // cambiamos a buscar por modelo moto
      await buscarPorModelo(selectorMotoState, dispatch)
      navigate(StepDetails[Step.SELECT_MARCA_MODELO].routes.mobile)
    }
  }

  const tabsArray = [
    {
      id: 0,
      icon: <NeumaticoHomeMoto className={styles.icon_neumatico} />,
      title: 'selector-moto.buscar_por_medida',
    },
    {
      id: 1,
      icon: <MotoIcon className={styles.icon_moto} />,
      title: 'selector-moto.buscar_por_moto',
    },
  ]

  return (
    <div className={showTabs ? styles.layout_selector : null}>
      {showTabs && (
        <ul className={styles.tabrow}>
          {tabsArray.map((tab) => {
            return (
              <li
                key={tab.id}
                onClick={() => onClickChangeTab(tab.id)}
                className={
                  tabActive === tab.id
                    ? `${styles.tab} ${styles.selected}`
                    : styles.tab
                }>
                <div>{tab.icon}</div>
                <div className={styles.tab_title}>{t(tab.title)}</div>
              </li>
            )
          })}
        </ul>
      )}
      <div className={showTabs ? styles.bodyWrapper : null}>
        <div className={styles.selector_top_area}>
          {(title || !hideSelectorSteps) && (
            <div className={styles.mobile_title}>
              <span className={styles.title_text}>{title}</span>
              {!hideSelectorSteps && (
                <ProgressBar
                  lastStep={false}
                  step={stepActive}
                  totalSteps={totalSteps}
                />
              )}
            </div>
          )}
          {subTitle && (
            <div>
              <span className={styles.resultados}>{subTitle}</span>
              {subTitle2 && (
                <span className={styles.subtitle2}>{subTitle2}</span>
              )}
              {showButtonEdit && (
                <span
                  onClick={onClickButtonEditMobile}
                  className={styles.edit_button}>
                  {t('resultados_neumaticos.editar')}
                </span>
              )}
            </div>
          )}
        </div>
        {children}
      </div>
      {showFooterMobile && <SelectorMotoFooterMobile step={stepActive} />}
    </div>
  )
}

export default LayoutSelectorMotoMobile
