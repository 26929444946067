import React from 'react'
import { t } from '../../../i18n'
import LeadFormContainer from '../Common/LeadFormContainer'
import SelectorFooterDesktop from '../Footers/SelectorFooterDesktop'
import {
  FunnelMotoScreenProps,
  StepsInMobile,
  TOTAL_MOBILE_STEPS,
} from '../SelectorMotoSteps'
import styles from './FormTusDatos.module.scss'
import LayoutSelectorMotoMobile from '../../../../../src/common/components/layouts/layoutSelectorMoto/LayoutSelectorMotoMobile'

type Props = FunnelMotoScreenProps
const FormTusDatos = ({
  dispatch,
  nextStep,
  previousStep,
  selectorMotoState,
}: Props) => {
  return (
    <LayoutSelectorMotoMobile
      title={t('preferences.cambio_neumaticos')}
      stepActive={StepsInMobile.INSERT_DATOS_LEAD}
      totalSteps={TOTAL_MOBILE_STEPS}>
      <div className={styles.title}>{t('steps.tus_datos')}</div>
      <LeadFormContainer
        dispatch={dispatch}
        selectorMotoState={selectorMotoState}
        previousStep={previousStep}
        nextStep={nextStep}
        footer={(props) => <SelectorFooterDesktop compareButton {...props} />}
      />
    </LayoutSelectorMotoMobile>
  )
}

export default FormTusDatos
