import { Router } from '@reach/router'
import { navigate } from 'gatsby'
import React, { useCallback, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import SearchResults from '../components/FunnelSelectorMoto/Pages/SearchResults'
import TusDatos from '../components/FunnelSelectorMoto/Pages/TusDatos'
import Medidas from '../components/FunnelSelectorMoto/Pages/Medidas'
import FormAncho from '../components/FunnelSelectorMoto/Pages/FormAncho'
import FormLlanta from '../components/FunnelSelectorMoto/Pages/FormLlanta'
import FormAlto from '../components/FunnelSelectorMoto/Pages/FormAlto'
import FormPreferencias from '../components/FunnelSelectorMoto/Pages/FormPreferencias'
import FormMarcas from '../components/FunnelSelectorMoto/Pages/FormMarcas'
import Step, {
  findNext,
  findPrevious,
  InSkipInfo,
  StepDetails,
  stepFromRoute,
} from '../components/FunnelSelectorMoto/SelectorMotoSteps'
import Layout from '../components/Layout'
import {
  BUSCAR_POR_MEDIDAS,
  reportStep,
} from '../context/actions/selectorMotoActions'
import { IAppState } from '../context/context'
import { SelectorMotoState } from '../context/reducers/selectorMotoReducer'
import Size from '../utils/media-queries-setup'
import {
  selectSelectorMoto,
  selectSkipInfoMoto,
} from '../context/selectors/selectorMotoSelectors'
import MarcaModelo from '../components/FunnelSelectorMoto/Pages/MarcaModelo'
import CilindradaModelo from '../components/FunnelSelectorMoto/Pages/CilindradaModelo'
import ModeloModelo from '../components/FunnelSelectorMoto/Pages/ModeloModelo'
import AplicacionModelo from '../components/FunnelSelectorMoto/Pages/AplicacionModelo'
import FabricacionModelo from '../components/FunnelSelectorMoto/Pages/FabricacionModelo'
import { useSelectedMenu } from '../../../sites/rodi/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../sites/rodi/constants/menu'

interface Props {
  selectorMotoState: SelectorMotoState
  dispatch: (action: any) => void
  skipInfo: InSkipInfo
}
interface ContentsProps extends Props {
  device: 'mobile' | 'desktop'
}
const SelectorContents = ({
  device,
  selectorMotoState,
  dispatch,
  skipInfo,
}: ContentsProps) => {
  const path =
    typeof document !== 'undefined' ? document.location.pathname : '/'

  let preventRender = typeof window === 'undefined'
  // Null checking because static building requires it
  const requestedStep = useMemo(() => stepFromRoute(path), [path])
  if (!requestedStep) {
    typeof window !== 'undefined' && navigate('/', { replace: true })
    preventRender = true
  }

  // The route must match the device being used, or otherwise we
  // redirect them to the correct route
  preventRender = useMemo(() => {
    if (preventRender) return true
    if (
      !requestedStep.routes[device] ||
      requestedStep.routes[device] !== path
    ) {
      const newStep = findPrevious(
        requestedStep.step + 1,
        device,
        skipInfo,
        selectorMotoState
      )
      navigate(newStep.routes[device], { replace: true })
      return true
    }
    return false
  }, [device, requestedStep, skipInfo])
  // And here we avoid rendering while the redirection is happening
  if (
    !preventRender &&
    (!requestedStep.routes[device] || requestedStep.routes[device] !== path)
  ) {
    preventRender = true
  }

  useEffect(() => {
    if (!requestedStep) return
    // Users may not open a skipped step
    if (
      requestedStep.skip &&
      requestedStep.skip(skipInfo, device, selectorMotoState)
    ) {
      const next = findNext(
        requestedStep.step,
        device,
        skipInfo,
        selectorMotoState
      )
      navigate(next.routes[device], { replace: requestedStep.replace })
      preventRender = true
      return
    }
    if (!requestedStep.isLoadable(selectorMotoState, skipInfo)) {
      const prev = findPrevious(
        requestedStep.step,
        device,
        skipInfo,
        selectorMotoState
      )
      navigate(prev.routes[device], { replace: true })
      preventRender = true
    }
  }, [requestedStep, device])

  const nextStep = useCallback(() => {
    const next = findNext(
      requestedStep.step,
      device,
      skipInfo,
      selectorMotoState
    )
    dispatch(reportStep(StepDetails[requestedStep.step], device))
    navigate(next.routes[device], { replace: requestedStep.replace })
  }, [requestedStep, dispatch, device, skipInfo, selectorMotoState])

  const previousStep = useCallback(
    (useBrowserHistory = true) => {
      if (
        requestedStep.step === Step.SELECT_ANCHO ||
        requestedStep.step === Step.SELECT_MARCA_MODELO
      ) {
        dispatch({ type: BUSCAR_POR_MEDIDAS })

        if (requestedStep.step === Step.SELECT_ANCHO) {
          navigate('/')
        } else if (requestedStep.step === Step.SELECT_MARCA_MODELO) {
          navigate(StepDetails[Step.SELECT_ANCHO].routes.mobile)
        }
        return
      }
      if (
        useBrowserHistory &&
        typeof history !== 'undefined' &&
        history.length > 1
      ) {
        history.back()
        return
      }
      const prev = findPrevious(
        requestedStep.step,
        device,
        skipInfo,
        selectorMotoState
      )
      navigate(prev.routes[device])
    },
    [requestedStep, dispatch, device, skipInfo]
  )

  if (preventRender) {
    return null
  }

  return (
    <Layout seoData={null} onGoBack={previousStep} selector={true}>
      <Router>
        <Medidas path={StepDetails[Step.INITIAL].routes.desktop} />
        <FormAncho
          path={StepDetails[Step.SELECT_ANCHO].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorMotoState={selectorMotoState}
        />
        <FormAlto
          path={StepDetails[Step.SELECT_ALTO].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorMotoState={selectorMotoState}
        />
        <FormLlanta
          path={StepDetails[Step.SELECT_LLANTA].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorMotoState={selectorMotoState}
        />
        <MarcaModelo
          path={StepDetails[Step.SELECT_MARCA_MODELO].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorMotoState={selectorMotoState}
        />
        <CilindradaModelo
          path={StepDetails[Step.SELECT_CILINDRADA_MODELO].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorMotoState={selectorMotoState}
        />
        <ModeloModelo
          path={StepDetails[Step.SELECT_MODELO_MODELO].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorMotoState={selectorMotoState}
        />
        <FabricacionModelo
          path={StepDetails[Step.SELECT_FABRICACION_MODELO].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorMotoState={selectorMotoState}
        />
        <AplicacionModelo
          path={StepDetails[Step.SELECT_APLICACION_MODELO].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorMotoState={selectorMotoState}
        />
        <FormMarcas
          path={StepDetails[Step.SELECT_MARCAS].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorMotoState={selectorMotoState}
        />
        <FormPreferencias
          path={StepDetails[Step.SELECT_PREFERENCIAS].routes.desktop}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorMotoState={selectorMotoState}
        />
        <TusDatos
          device={device}
          path={StepDetails[Step.INSERT_DATOS_LEAD].routes.mobile}
          nextStep={nextStep}
          previousStep={previousStep}
          dispatch={dispatch}
          selectorMotoState={selectorMotoState}
        />
        <SearchResults
          path={StepDetails[Step.SHOW_RESULTADOS].routes.desktop}
          nextStep={nextStep}
          previousStep={previousStep}
          selectorMotoState={selectorMotoState}
          page="resultados"
        />

        <SearchResults
          path={StepDetails[Step.SHOW_CAMARAS].routes.desktop}
          nextStep={nextStep}
          previousStep={previousStep}
          selectorMotoState={selectorMotoState}
          page="camaras"
        />
      </Router>
    </Layout>
  )
}

const SelectorMoto = (props: Props) => {
  useSelectedMenu(MenuPrincipal.MOTO)
  return (
    <Size.Matcher
      mobile={<SelectorContents device="mobile" {...props} />}
      desktop={<SelectorContents device="desktop" {...props} />}
    />
  )
}

const SelectorMotoPage = connect((state: IAppState) => ({
  selectorMotoState: selectSelectorMoto(state),
  skipInfo: selectSkipInfoMoto(state),
}))(SelectorMoto)

export default SelectorMotoPage
