import React from 'react'
import { FunnelMotoScreenProps, StepsInMobile } from '../SelectorMotoSteps'
import SelectorContainerMotoMobile from '../Common/SelectorContainerMotoMobile'

type Props = FunnelMotoScreenProps
const FormAncho = (props: Props) => {
  return (
    <SelectorContainerMotoMobile
      {...props}
      stepActive={StepsInMobile.SELECT_ANCHO}
      medidaActual={'ancho'}
    />
  )
}

export default FormAncho
