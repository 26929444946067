import React from 'react'
import SelectorSteps from '../layoutSelector/Desktop/SelectorSteps'
import { t } from '../../../../../sites/rodi/i18n'
import { dataSteps, LayoutSelectorMotoProps } from './LayoutSelectorMoto'
import styles from './LayoutSelectorMotoDesktop.module.scss'

const LayoutSelectorMotoDesktop = ({
  title,
  subTitle = '',
  subTitle2 = '',
  hideSelectorSteps = false,
  showButtonEdit = false,
  stepActive = 0,
  customSteps = dataSteps,
  onClickButtonEditDesktop,
  children,
}: LayoutSelectorMotoProps) => {
  return (
    <div className={styles.selector_container}>
      <div className={styles.children_container}>
        <div className={styles.header}>
          {(title || subTitle) && (
            <div className={styles.title_subtitle}>
              {title && <div className={styles.title}>{title}</div>}
              {subTitle ? (
                <div className={styles.subTitle}>
                  {subTitle}
                  {subTitle2 && (
                    <span className={styles.subtitle2}>{subTitle2}</span>
                  )}
                  {showButtonEdit ? (
                    <div
                      className={styles.edit_button}
                      onClick={onClickButtonEditDesktop}>
                      {t('resultados_neumaticos.editar')}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          )}
          {!hideSelectorSteps ? (
            <SelectorSteps data={customSteps} stepActive={stepActive} />
          ) : null}
        </div>
        {children}
      </div>
    </div>
  )
}

export default LayoutSelectorMotoDesktop
