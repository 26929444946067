import React from 'react'
import {
  FunnelMotoScreenProps,
  StepsFeaturesInMobile,
} from '../SelectorMotoSteps'
import SelectorContainerMotoMobile from '../Common/SelectorContainerMotoMobile'

type Props = FunnelMotoScreenProps
const MarcaModelo = (props: Props) => {
  return (
    <SelectorContainerMotoMobile
      {...props}
      stepActive={StepsFeaturesInMobile.SELECT_MARCA_MODELO}
      medidaActual={'marca'}
    />
  )
}

export default MarcaModelo
