import withMarcas, {
  InjectedMarcas,
} from '../../../../../../src/common/components/withMarcas'
import styles from '../ContenedorPreferencias.module.scss'
import RadioButtonYellow from '../../../../../../src/common/components/radioButtonYellow/RadioButtonYellow'
import React, { useContext, useEffect, useState } from 'react'
import {
  resetMarca,
  selectMarca,
} from '../../../../../../src/common/context/actions/selectorMotoActions'
import { SelectorMotoState } from '../../../../../../src/common/context/reducers/selectorMotoReducer'
import MarcasMotoList from '../../Common/MarcasMotoList'
import AppContext from '../../../../../../src/common/context/context'
import { t } from '../../../../i18n'

interface Props extends InjectedMarcas {
  selectorMotoState?: SelectorMotoState
}

const SelectorMarcas = ({ selectorMotoState, marcas }: Props) => {
  const { dispatch } = useContext(AppContext)
  const [showMarcasList, setShowMarcasList] = useState(
    !!selectorMotoState.marca
  )
  const onMarcaSelect = (value) => {
    dispatch(selectMarca(value))
  }
  const onHandleRadioButtonChange = (event) => {
    if (event === 'marcas-list') {
      setShowMarcasList(true)
    } else {
      setShowMarcasList(false)
      dispatch(resetMarca())
    }
  }

  useEffect(() => {
    // si tenemos marca forzamos a seleccionar la marca que teníamos
    // para que los filtros del selector se pongan con la misma marca elegida
    if (selectorMotoState.marca) {
      dispatch(selectMarca(selectorMotoState.marca))
    }
  }, [])

  return (
    <div className={styles.caracteristica}>
      <div className={styles.caracteristica_title}>
        {t('selector-desktop.marca')}
      </div>
      <div className={styles.caracteristica_content}>
        <RadioButtonYellow
          onChange={onHandleRadioButtonChange}
          label1={
            <div className={styles.label_title}>{t('menuHeader.todas')}</div>
          }
          checked={!showMarcasList}
          name="marca"
          value="marcas-all"
        />
        <RadioButtonYellow
          onChange={onHandleRadioButtonChange}
          checked={showMarcasList}
          label1={
            <div className={styles.label_title}>{t('preferences.select')}</div>
          }
          name="marca"
          value="marcas-list"
        />
        {showMarcasList && (
          <MarcasMotoList
            selectedMarca={selectorMotoState.marca}
            onMarcaSelect={onMarcaSelect}
            marcasList={marcas}
          />
        )}
      </div>
    </div>
  )
}

export default withMarcas(SelectorMarcas)
