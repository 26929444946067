import styles from '../Results/searchResults.module.scss'
import { t } from '../../../../../src/common/i18n'
import { DEFAULT_PAGE_SIZE } from '../../../../../src/common/context/actions/searchMotoActions'
import React from 'react'
import Pagination from 'react-js-pagination'

interface MobilePaginationProps {
  handlePageChange: (page: number) => void
  currentPage: number
  totalPages: number
  totalItems: number
}

const MobilePagination = ({
  handlePageChange,
  currentPage,
  totalPages,
  totalItems,
}: MobilePaginationProps) => (
  <div className={styles.pagination_mobile}>
    <span>
      {t('resultados_neumaticos.pagina')} {currentPage} de {totalPages}
    </span>
    <Pagination
      activePage={currentPage}
      itemsCountPerPage={DEFAULT_PAGE_SIZE}
      totalItemsCount={totalItems}
      itemClass="item"
      hideDisabled
      hideFirstLastPages
      pageRangeDisplayed={1}
      onChange={handlePageChange}
      prevPageText={`< ${t('resultados_neumaticos.anterior')}`}
      nextPageText={`${t('resultados_neumaticos.siguiente')} >`}
    />
  </div>
)

export default MobilePagination
