import React, { useCallback, useContext, useState } from 'react'
import styles from '../searchResults.module.scss'
import AppContext from '../../../../../../src/common/context/context'
import DropDown from '../../../../../../src/common/components/dropDown/DropDown'
import SpinnerWithText from '../../../../../../src/common/components/spinnerWithText/SpinnerWithText'
import NoResultsMessage from '../../../../../../src/common/components/noResultsMessage/NoResultsMessage'
import CamaraMotoResultsCard from '../../../camaraMotoResultsCard/CamaraMotoResultsCard'
import Size from '../../../../../../src/common/utils/media-queries-setup'
import MobilePagination from '../../Common/MobilePagination'
import Pagination from 'react-js-pagination'
import {
  OrderEntry,
  ORDER_OPTIONS,
} from '../../../../context/actions/searchActions'
import { DEFAULT_PAGE_SIZE } from '../../../../context/actions/searchMotoActions'
import { searchCamaraMoto } from '../../../../context/actions/searchCamaraMotoActions'
import { t } from '../../../../i18n'
import { CamaraMotoResult } from '../../../../api/searchCamaraMoto'
import { SearchCamaraMotoState } from '../../../../context/reducers/searchCamaraMotoReducer'

interface Props {
  onSelectCamara: (camara: CamaraMotoResult) => void
  searchCamaraMotoState: Partial<SearchCamaraMotoState>
}

const CamarasResults = ({
  searchCamaraMotoState,
  onSelectCamara,
  ...props
}: Props) => {
  const { dispatch } = useContext(AppContext)
  const [order, setOrder] = useState(ORDER_OPTIONS[0])
  const camaras = searchCamaraMotoState.results.camaras

  const callSearchCamaras = useCallback(
    (pageNumber: number, order: OrderEntry) => {
      dispatch(
        searchCamaraMoto({
          pagination: {
            pageSize: DEFAULT_PAGE_SIZE,
            page: pageNumber,
          },
          order: order.field,
          direction: order.direction,
        })
      )
    },
    [dispatch]
  )

  const handlePageChange = (page: number) => {
    callSearchCamaras(page - 1, order)
  }

  const changeOrder = (event) => {
    event.preventDefault()
    const value = event.target.value
    const order = ORDER_OPTIONS.find((o) => o.value === value)
    setOrder(order)
    callSearchCamaras(0, order)
  }

  return (
    <React.Fragment>
      <section className={styles.tyresSearcDBFilter_wrapper}>
        <div className={styles.order_by_wrapper}>
          <div>
            <span className={styles.mostrando_text}>
              {t('resultados_neumaticos.mostrando')}
            </span>
            {searchCamaraMotoState.results.camaras.length} de{' '}
            {searchCamaraMotoState.results.total}{' '}
            {t('resultados_neumaticos.camaras')}
          </div>
          <div className={styles.order}>
            <span className={styles.order_text}>
              {t('resultados_neumaticos.ordenar_por')}:{' '}
            </span>
            <DropDown
              name="orderBy"
              onChange={changeOrder}
              options={ORDER_OPTIONS}
            />
          </div>
        </div>

        {searchCamaraMotoState.loading ? (
          <SpinnerWithText
            text={t('selector.buscando_neumaticos')}
            className={styles.loading_container}
          />
        ) : (
          <React.Fragment>
            <div className={styles.neumaticos_cards_wrapper}>
              {camaras &&
                camaras.map((camara, index) => (
                  <CamaraMotoResultsCard
                    onSelectCamara={onSelectCamara}
                    key={index}
                    camara={camara}
                  />
                ))}
            </div>
            {camaras && !camaras.length && <NoResultsMessage />}
          </React.Fragment>
        )}

        {camaras && camaras.length > 0 ? (
          <Size.Matcher
            desktop={
              <Pagination
                activePage={searchCamaraMotoState.query.pagination.page + 1}
                itemsCountPerPage={DEFAULT_PAGE_SIZE}
                totalItemsCount={searchCamaraMotoState.results.total}
                itemClass="item"
                hideDisabled
                hideFirstLastPages
                pageRangeDisplayed={5}
                onChange={handlePageChange}
                prevPageText={`< ${t('resultados_neumaticos.anterior')}`}
                nextPageText={`${t('resultados_neumaticos.siguiente')} >`}
              />
            }
            mobile={
              <MobilePagination
                handlePageChange={handlePageChange}
                currentPage={searchCamaraMotoState.query.pagination.page + 1}
                totalPages={searchCamaraMotoState.results.pages}
                totalItems={searchCamaraMotoState.results.total}
              />
            }
          />
        ) : null}
      </section>
    </React.Fragment>
  )
}

export default CamarasResults
