import { NeumaticoMotoResult } from '../../../api/searchMoto'
import styles from './searchResults.module.scss'
import { navigate } from 'gatsby'
import Step, { FunnelMotoScreenProps, StepDetails } from '../SelectorMotoSteps'
import DBResults from './SearchResults/DBResults'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { t } from '../../../i18n'
import SelectorModalMoto from './SelectorModalMoto'
import React, { useContext, useEffect } from 'react'
import { searchMoto } from '../../../context/actions/searchMotoActions'
import CamarasResults from './SearchResults/CamarasResults'
import * as CestaActions from '../../../../../src/common/context/actions/cestaActions'
import { DataSteps, Steps } from '../../cesta/Steps'
import { validateBuscarPorMedidas } from '../../../context/reducers/selectorMotoReducer'
import { registrarMarcaMoto } from '../../../context/actions/selectorMotoActions'
import { searchCamaraMoto } from '../../../context/actions/searchCamaraMotoActions'
import { CamaraMotoResult } from '../../../api/searchCamaraMoto'
import AppContext from '../../../../../src/common/context/context'
import Size from '../../../../../src/common/utils/media-queries-setup'

interface Props extends FunnelMotoScreenProps {
  page: 'resultados' | 'camaras'
  showSelectorModal: boolean
  closeSelectorModal: () => void
}

export const SearchResultsContent = ({
  previousStep,
  nextStep,
  showSelectorModal,
  closeSelectorModal,
  dispatch,
  page,
}: Props) => {
  const { searchMotoState, selectorMotoState, searchCamaraMotoState } =
    useContext(AppContext)
  const searchDatabase = () => {
    dispatch(searchMoto())
  }

  const searchCamarasDatabase = () => {
    dispatch(searchCamaraMoto())
  }

  const onSelectNeumatico = async (neumatico: NeumaticoMotoResult) => {
    dispatch(
      CestaActions.addProduct(
        {
          id: neumatico.id,
          categoria: neumatico.categoria,
          marca: neumatico.marca,
          type: 'neumatico',
        },
        page === 'camaras' ? 'camaras' : 'database'
      )
    )
    dispatch(CestaActions.setStep(Steps.TU_SOLICITUD))
    await navigate(DataSteps[Steps.TU_SOLICITUD].route)
  }

  const onSelectCamara = async (camara: CamaraMotoResult) => {
    dispatch(
      CestaActions.addProduct(
        {
          id: camara.id,
          type: camara.tipo_producto,
          marca: camara.marca,
        },
        page === 'camaras' ? 'camaras' : 'database'
      )
    )
    dispatch(CestaActions.setStep(Steps.TU_SOLICITUD))
    await navigate(DataSteps[Steps.TU_SOLICITUD].route)
  }

  const device = Size.useMedia({
    mobile: 'MOBILE',
    tablet: 'TABLET',
    desktop: 'DESKTOP',
  }) as 'MOBILE' | 'TABLET' | 'DESKTOP'

  const init = () => {
    if (
      validateBuscarPorMedidas(selectorMotoState) &&
      !showSelectorModal &&
      !selectorMotoState.loading_medidas &&
      !searchMotoState.loading &&
      !searchCamaraMotoState.loading
    ) {
      dispatch(registrarMarcaMoto(device))
      searchDatabase()
      searchCamarasDatabase()
    }
  }
  /**
   * Cuando se cambia la búsqueda desde el modal de edición
   */
  const onSelectorModalSubmit = async () => {
    closeSelectorModal()
    await navigate(StepDetails[Step.SHOW_RESULTADOS].routes.desktop)
  }

  useEffect(() => {
    init()
  }, [selectorMotoState])

  return (
    <div className={styles.router_div}>
      <div className={styles.search_results_wrapper}>
        <ul className={styles.tabs}>
          {/* DB */}
          <li
            className={
              page === 'resultados'
                ? `${styles.tab} ${styles.is_active}`
                : `${styles.tab} ${styles.no_active}`
            }
            onClick={async () => {
              await navigate(StepDetails[Step.SHOW_RESULTADOS].routes.desktop, {
                replace: true,
              })
            }}>
            <a className={styles.tab_text}>
              {t('resultados_neumaticos.neumaticos')} (
              {searchMotoState.loading ? '...' : searchMotoState.results.total})
            </a>
          </li>

          {/* Camaras y Mousses */}
          <li
            className={
              page === 'camaras'
                ? `${styles.tab} ${styles.is_active}`
                : `${styles.tab} ${styles.no_active}`
            }
            onClick={async () => {
              await navigate(StepDetails[Step.SHOW_CAMARAS].routes.desktop, {
                replace: true,
              })
            }}>
            <a className={styles.tab_text}>
              {t('resultados_neumaticos.camaras')} (
              {searchCamaraMotoState.loading
                ? '...'
                : searchCamaraMotoState.results.total}
              )
            </a>
          </li>
        </ul>

        <div className={styles.results_container}>
          {page === 'resultados' ? (
            <DBResults
              onSelectNeumatico={onSelectNeumatico}
              searchMotoState={searchMotoState}
            />
          ) : (
            <CamarasResults
              searchCamaraMotoState={searchCamaraMotoState}
              onSelectCamara={onSelectCamara}
            />
          )}
        </div>

        <div className={styles.resultsFooter}>
          <div className={styles.resultsFooter_left}>
            <span className={styles.option} onClick={() => previousStep(false)}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                className={styles.go_back_icon}
              />
              {t('cesta.general.volver_atras')}
            </span>
            <span className={styles.option}>|</span>
            <span
              className={styles.option}
              onClick={async () => {
                await navigate(StepDetails[Step.INITIAL].routes.desktop)
              }}>
              {t('resultados_neumaticos.cambiar_preferencias')}
            </span>
          </div>
        </div>
        <SelectorModalMoto
          closeModal={closeSelectorModal}
          showModal={showSelectorModal}
          title={t('selector.modifica_busqueda')}
          handleSubmit={onSelectorModalSubmit}
          showMarcas={false}
        />
      </div>
    </div>
  )
}
