import React from 'react'
import Size from '../../../utils/media-queries-setup'
import LayoutSelectorMotoMobile from './LayoutSelectorMotoMobile'
import LayoutSelectorMotoDesktop from './LayoutSelectorMotoDesktop'

interface InSteps {
  step: number
  name: string
}

export const dataSteps = [
  {
    step: 1,
    name: 'steps.datos_neumaticos',
  },
  {
    step: 2,
    name: 'steps.preferencias',
  },
  {
    step: 3,
    name: 'steps.tus_datos',
  },
  {
    step: 4,
    name: 'steps.resultados',
  },
]

export type LayoutSelectorMotoProps = {
  title
  subTitle?: string
  subTitle2?: string
  stepActive: number
  totalSteps?: number
  mobileStep?: number
  hideSelectorSteps?: boolean | undefined
  showButtonEdit?: boolean
  customSteps?: InSteps[]
  onClickButtonEditDesktop?: () => void
  onClickButtonEditMobile?: () => void
  showFooterMobile?: boolean
  children?:
    | string
    | (string | JSX.Element)[]
    | JSX.Element
    | React.ReactChildren
  showTabs?: boolean
}

function LayoutSelector({ ...props }: LayoutSelectorMotoProps) {
  return (
    <Size.Matcher
      mobile={<LayoutSelectorMotoMobile {...props} />}
      desktop={<LayoutSelectorMotoDesktop {...props} />}
    />
  )
}

export default LayoutSelector
