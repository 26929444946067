import { RevisionMarca } from '../../../../api/getMarcasByCilindrada'
import { OptionMedida } from '../../../../../../common/api/selectorMoto'
import { OptionGrouped } from '../../../../../../shared/components/DropDown/DropDown'
import { t } from '../../../../../../common/i18n'

interface Props {
  marcas: RevisionMarca[] | OptionMedida[]
  loading: boolean
  error: string
}
export default function useMarcasPrincipales({
  marcas,
  loading,
  error,
}: Props) {
  if (error) {
    throw new Error('Error al cargar las marcas')
  }

  if (loading || !marcas) {
    // You can return a loading state here if needed
    return {
      marcasWithPrincipales: [],
    }
  }

  const marcasPrincipales = marcas
    .filter((marca) => marca.principal)
    .sort((a, b) => {
      if (a.orden === null) return 1
      if (b.orden === null) return -1
      return a.orden - b.orden
    })

  const options: OptionGrouped[] = [
    {
      label: t('selector-moto.marcaPrincipales'),
      options: marcasPrincipales,
    },
    {
      label: marcasPrincipales.length > 0 ? '----------------------------' : '',
      options: marcas
        .filter((marca) => !marca.principal)
        .sort((a, b) => a.label.localeCompare(b.label)),
    },
  ]
  return {
    options,
  }
}
