import React from 'react'
import { FunnelMotoScreenProps } from '../SelectorMotoSteps'
import { t } from '../../../i18n'
import LayoutSelectorMotoDesktop from '../../../../../src/common/components/layouts/layoutSelectorMoto/LayoutSelectorMotoDesktop'
import ContenedorPreferencias from '../Desktop/ContenedorPreferencias'
import MainFormContainer from '../../../../../src/common/components/layouts/mainFormContainer/MainFormContainer'
import FunnelSideBar from '../../../../../src/common/components/funnelSideBar/FunnelSideBar'

type Props = FunnelMotoScreenProps
const FormPreferencias = (props: Props) => {
  return (
    <LayoutSelectorMotoDesktop
      title={t('preferences.cambio_neumatico_moto')}
      subTitle={t('preferences.tus_preferencias')}
      stepActive={2}>
      <MainFormContainer>
        <ContenedorPreferencias {...props} />
      </MainFormContainer>
      <FunnelSideBar />
    </LayoutSelectorMotoDesktop>
  )
}

export default FormPreferencias
