import React from 'react'
import {
  FunnelMotoScreenProps,
  StepsFeaturesInMobile,
} from '../SelectorMotoSteps'
import SelectorContainerMotoMobile from '../Common/SelectorContainerMotoMobile'
import {
  buscarMedidasTyres,
  buscarPorModelo,
} from '../../../../../src/common/context/actions/selectorMotoActions'

type Props = FunnelMotoScreenProps
const AplicacionModelo = ({
  dispatch,
  previousStep,
  nextStep,
  selectorMotoState,
}: Props) => {
  const onNextStep = () => {
    buscarPorModelo(selectorMotoState, dispatch)
    buscarMedidasTyres(selectorMotoState, dispatch)
    nextStep()
  }
  return (
    <SelectorContainerMotoMobile
      dispatch={dispatch}
      selectorMotoState={selectorMotoState}
      nextStep={onNextStep}
      previousStep={previousStep}
      stepActive={StepsFeaturesInMobile.SELECT_APLICACION_MODELO}
      medidaActual={'aplicacion'}
    />
  )
}

export default AplicacionModelo
