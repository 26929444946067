import { FieldErrors } from 'react-hook-form/dist/types'
import { LeadInfo } from '../../../context/reducers/userReducer'
import { t } from '../../../i18n'
import route from '../../../../../src/common/utils/route'
import CheckBoxYellowCesta from '../../../../../src/common/components/checkboxYellowCesta/CheckBoxYellowCesta'
import FormError from '../../../../../src/common/components/FormError/FormError'
import InputCesta from '../../../../../src/common/components/inputCesta/InputCesta'
import LoginContainer from '../../../../../src/common/components/loginForm/LoginContainer'
import StandardModal from '../../../../../src/common/components/modal/standardModal/StandardModal'
import styles from './leadForm.module.scss'
import InputCodigoPostal from '../../../../../src/specific/components/inputCodigoPostal/InputCodigoPostal'
import React from 'react'

export type RegisterData = LeadInfo & {
  checkboxPrivacy: string
}

interface Props {
  email: string
  handleSubmit: any
  onSubmit: any
  isModalOpen: boolean
  onCloseModal: () => void
  neumaticosEncontrados: null | number
  errors: FieldErrors<RegisterData>
  formRef: any
  previousStep: () => void
  footer: (props: {}) => JSX.Element
  formState: any
  nextStep: () => void
  clearError: (name: string) => void
  triggerValidation: (
    payload?: { name: string; value?: any } | { name: string; value?: any }[]
  ) => Promise<boolean>
}

export const LeadForm = ({
  handleSubmit,
  onSubmit,
  neumaticosEncontrados,
  errors,
  formRef,
  email,
  footer,
  isModalOpen,
  onCloseModal,
  formState,
  nextStep,
  clearError,
  triggerValidation,
}: Props) => (
  <div className={styles.personaldata_container}>
    <div className={styles.title}>
      {t('pre_register.neumaticos_encontrados', {
        count:
          neumaticosEncontrados === null ? '...' : neumaticosEncontrados + 6,
      })}
      <div>{t('pre_register.dejar_datos')}</div>
    </div>

    <form
      className={styles.login__form}
      onChange={(e) => {
        const target = e.target as HTMLInputElement
        // Revalidar cualquier campo cuando cambia su valor
        clearError(target.name)
        triggerValidation({
          name: target.name,
        })
      }}
      onSubmit={(e) => {
        // Validar todo el formulario antes de tratar de enviar
        triggerValidation()
        handleSubmit(onSubmit)(e)
      }}>
      <InputCesta
        label={t('formLabels.email')}
        name="email"
        refe={formRef}
        errors={errors}
        inputSize="big"
      />
      <InputCesta
        label={t('formLabels.phone')}
        name="phone"
        refe={formRef}
        errors={errors}
      />
      <InputCodigoPostal
        label={t('formLabels.post_code')}
        name="postCode"
        refe={formRef}
        errors={errors}
      />
      <div className={styles.checkbox_container}>
        <div className={styles.checkboxYellow_wrapper}>
          <CheckBoxYellowCesta
            name="wantsInfo"
            refe={formRef}
            label={<div className={styles.label}>{t('registrado.acepto')}</div>}
          />
        </div>
        <div className={styles.checkboxYellow_wrapper}>
          <CheckBoxYellowCesta
            name="checkboxPrivacy"
            refe={formRef}
            label={
              <div className={styles.label}>
                <span>{t('registerForm.checkbox-privacy-read')}</span>
                <a
                  className={styles.spanlabel}
                  href={route('politica-privacidad.index')}
                  target="_blank"
                  rel="noreferrer">
                  {t('registerForm.checkbox-privacy-policy')}
                </a>
              </div>
            }
          />
          <FormError errors={errors} field="checkboxPrivacy" />
        </div>
      </div>
      {footer({
        count: neumaticosEncontrados === null ? '...' : neumaticosEncontrados,
        formState,
      })}
    </form>

    <StandardModal
      modalClass={styles.login_modal}
      closeModal={onCloseModal}
      showModal={isModalOpen}
      title={'Usuario registrado'}>
      <div className={styles.modal_form_cont}>
        {/* <p className={styles.login_header}>
          <FormattedMessage id="preferences.login-header" />
        </p> */}
        <LoginContainer
          userIdentified={isModalOpen}
          preFillEmail={email}
          onLogin={nextStep}
        />
      </div>
    </StandardModal>
  </div>
)
