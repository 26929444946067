import React from 'react'
import {
  FunnelMotoScreenProps,
  StepsFeaturesInMobile,
} from '../SelectorMotoSteps'
import SelectorContainerMotoMobile from '../Common/SelectorContainerMotoMobile'

type Props = FunnelMotoScreenProps
const FabricacionModelo = (props: Props) => {
  return (
    <SelectorContainerMotoMobile
      {...props}
      stepActive={StepsFeaturesInMobile.SELECT_FABRICACION_MODELO}
      medidaActual={'fabricacion'}
    />
  )
}

export default FabricacionModelo
