import { MenuPrincipal } from '../../constants/menu'

export const SET = 'MENU::SET'

export function setMenuPrincipal(menu: MenuPrincipal) {
  return {
    type: SET,
    payload: menu,
  }
}
