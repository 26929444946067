import { connect } from 'react-redux'
import {
  buscarPorMedidas,
  reset,
  selectMedidaMoto,
} from '../../../context/actions/selectorMotoActions'
import AppContext, {
  IAppState,
} from '../../../../../src/common/context/context'
import { validateBuscarPorMedidas } from '../../../context/reducers/selectorMotoReducer'
import {
  selectSelectorMoto,
  selectSkipInfoMoto,
} from '../../../context/selectors/selectorMotoSelectors'
import { SelectorProps } from '../SelectorMotoSteps'
import { useContext, useEffect } from 'react'
import route from '../../../../../src/common/utils/route'

type BaseProps = Pick<SelectorProps, 'skipInfo' | 'showMarcas' | 'origin'>

interface Props extends BaseProps {
  children: (props: SelectorProps) => JSX.Element
  onSumbitMedidas?: () => void
}

const SelectorContainerMoto = ({
  skipInfo,
  children,
  showMarcas,
  onSumbitMedidas,
  origin,
}: Props) => {
  const { dispatch, selectorMotoState } = useContext(AppContext)

  const handleChange = (event, name) => {
    if (event) {
      let value
      if (event.target) {
        event.preventDefault()
        value = event.target.value
      } else {
        value = event.value
      }
      switch (name) {
        case 'ancho':
          dispatch(
            selectMedidaMoto({
              name: 'alto',
              value: '',
            })
          )
          dispatch(
            selectMedidaMoto({
              name: 'llanta',
              value: '',
            })
          )
          break
        case 'alto':
          dispatch(
            selectMedidaMoto({
              name: 'llanta',
              value: '',
            })
          )
          break
      }
      dispatch(
        selectMedidaMoto({
          name,
          value,
        })
      )
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (validateBuscarPorMedidas(selectorMotoState)) {
      await buscarPorMedidas(selectorMotoState, dispatch)
      onSumbitMedidas()
    }
  }

  useEffect(() => {
    if (
      !selectorMotoState.ancho.options.length &&
      !selectorMotoState.ancho.loading
    ) {
      dispatch(
        reset({
          keepValues: false,
          marca: null,
          origen: route('index'),
        })
      )
    }
  }, [])

  return children({
    skipInfo,
    selectorMotoState,
    handleChange,
    handleSubmit,
    showMarcas,
    origin,
  })
}

const ConnectedSelectorContainerMoto = connect((state: IAppState) => ({
  skipInfo: selectSkipInfoMoto(state),
  selectorState: selectSelectorMoto(state),
}))(SelectorContainerMoto)

export default ConnectedSelectorContainerMoto
