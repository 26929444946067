import React from 'react'
import styles from './marcasList.module.scss'
import { t } from '../../../i18n'
import { OptionMedida } from '../../../api/selectorMoto'

interface Props {
  marcas: OptionMedida[]
  onMarcaSelect: (marca: string) => void
  onSelectTodas: () => void
}

const MarcasListMobile = ({ marcas, onMarcaSelect, onSelectTodas }: Props) => {
  return (
    <React.Fragment>
      <div onClick={onSelectTodas} className={styles.all_marcas_selection}>
        {t('ficha-marca.todas')}
      </div>

      <ul className={styles.option_list_container}>
        {marcas.map((item, index) => (
          <li
            className={styles.option_list_item}
            key={index}
            onClick={() => onMarcaSelect(item.value)}
            value={item.value}>
            {item.label}
          </li>
        ))}
      </ul>
    </React.Fragment>
  )
}
export default MarcasListMobile
