import React, { useEffect, useMemo, useRef, useState } from 'react'
import Next from '../../../../../src/common/assets/images/Talleres/next.svg'
import Button from '../../../../../src/common/components/buttons/Button'
import InputDropDown from '../../../../shared/components/Input/InputDropDown'
import { validateBuscarPorMedidas } from '../../../context/reducers/selectorMotoReducer'
import { t } from '../../../i18n'
import SVGMotoSelectorMedidas from '../Common/SVGMotoSelectorMedidas/SVGMotoSelectorMedidas'
import { SelectorProps } from '../SelectorMotoSteps'
import styles from './selectorMotoDesktop.module.scss'

const SelectorMotoDesktop = ({
  selectorMotoState,
  handleChange,
  handleSubmit,
}: SelectorProps) => {
  const [showArrow, setShowArrow] = useState(null)
  const anchoOptions = useMemo(
    () =>
      selectorMotoState.ancho.options.sort(
        (a, b) => parseInt(a.value.toString()) - parseInt(b.value.toString())
      ),
    [selectorMotoState.ancho.options]
  )
  const altoOptions = useMemo(
    () =>
      selectorMotoState.alto.options.sort(
        (a, b) => parseInt(a.value.toString()) - parseInt(b.value.toString())
      ),
    [selectorMotoState.alto.options]
  )

  const altoRef = useRef(null)
  const llantaRef = useRef(null)
  const disableAltura =
    selectorMotoState.alto.options.length === 1 &&
    selectorMotoState.alto.options[0].value === null
  const buttonValidate = !validateBuscarPorMedidas(selectorMotoState)

  const onMenuOpen = (id) => {
    setShowArrow(id)
  }

  const onMenuClose = () => {
    setShowArrow(null)
  }

  const onChangeFeature = (event, name) => {
    if (event) {
      handleChange(event, name)
    }
  }

  useEffect(() => {
    if (altoRef.current && !selectorMotoState.alto.value) {
      altoRef.current.select.clearValue()
    }
    if (llantaRef.current && !selectorMotoState.llanta.value) {
      llantaRef.current.select.clearValue()
    }
  }, [selectorMotoState])

  return (
    <div className={styles.selector_home_container}>
      <div className={styles.neumatico_wrapper}>
        <SVGMotoSelectorMedidas />
      </div>
      <form onSubmit={handleSubmit}>
        <div className={styles.row}>
          <div className={styles.form_dropdown}>
            {showArrow === 'ancho' && (
              <div className={styles.arrowAncho}>
                <div className={styles.firstPart} />
                <div className={styles.secondPart} />
                <div className={styles.thirdPart} />
              </div>
            )}
            <InputDropDown
              placeholder={t('search-results.select')}
              labelId="selector-moto.anchura"
              name="ancho"
              options={anchoOptions}
              onChange={(e) => onChangeFeature(e, 'ancho')}
              labelAbove={true}
              required
              searchable={true}
              clearable={false}
              errors={{ ancho: null }}
              onFocus={() => onMenuOpen('ancho')}
              onBlur={() => onMenuClose()}
            />
          </div>

          <div className={styles.form_dropdown}>
            {showArrow === 'alto' && (
              <div className={styles.arrowAltura}>
                <div className={styles.firstPart} />
                <div className={styles.secondPart} />
                <div className={styles.thirdPart} />
              </div>
            )}
            <InputDropDown
              formRef={altoRef}
              placeholder={t('search-results.select')}
              labelId="selector-moto.altura"
              name="alto"
              options={altoOptions}
              onChange={(e) => onChangeFeature(e, 'alto')}
              labelAbove={true}
              disabled={disableAltura}
              required
              searchable={true}
              clearable={false}
              errors={{ alto: null }}
              onFocus={() => onMenuOpen('alto')}
              onBlur={() => onMenuClose()}
            />
          </div>

          <div className={styles.form_dropdown}>
            {showArrow === 'llanta' && (
              <div className={styles.arrowLlanta}>
                <div className={styles.firstPart} />
                <div className={styles.secondPart} />
                <div className={styles.thirdPart} />
              </div>
            )}
            <InputDropDown
              formRef={llantaRef}
              disabled={selectorMotoState.llanta.options.length === 0}
              placeholder={t('search-results.select')}
              labelId="selector-moto.llanta"
              name="llanta"
              options={selectorMotoState.llanta.options}
              onChange={(e) => onChangeFeature(e, 'llanta')}
              labelAbove={true}
              required
              searchable={true}
              clearable={false}
              errors={{ llanta: null }}
              onFocus={() => onMenuOpen('llanta')}
              onBlur={() => onMenuClose()}
            />
          </div>
        </div>
      </form>

      <div className={styles.button_wrapper}>
        <Button
          disabled={buttonValidate}
          buttonClass="next_button"
          onClick={handleSubmit}>
          {t('selector-desktop.buscar')}
          <Next />
        </Button>
      </div>
    </div>
  )
}

export default SelectorMotoDesktop
