import styles from './index.module.scss'
import NeumaticoMotoMobile from '../../NeumaticoMotoMobile/NeumaticoMotoMobile'
import LoadingOptions from '../Common/LoadingOptions/LoadingOptions'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import {
  Aplicacion,
  FeatureNameModeloMoto,
  MedidaName,
  Selectable,
  SelectorMotoState,
} from '../../../context/reducers/selectorMotoReducer'

interface Props {
  onHandleClick: (value: string) => void
  selectorMotoState: SelectorMotoState
  stepActive: number
  medidaActual: MedidaName | FeatureNameModeloMoto
  showImageTyre: boolean
  selectable: Selectable<string> | Selectable<Aplicacion>
  title: string
  subtitle: string
  mostrarMas: string
  mostrarMenos: string
}

const NUMBER_FOR_PAGE = 12
const SelectorMedidasMobile = ({
  selectorMotoState,
  medidaActual,
  onHandleClick,
  showImageTyre,
  selectable,
  title = '',
  subtitle = '',
  mostrarMas = '',
  mostrarMenos = '',
}: Props) => {
  const [showMore, setShowMore] = useState(false)

  const toggleShowMore = () => {
    setShowMore(!showMore)
  }
  return (
    <div className={styles.ancho_wrapper}>
      {showImageTyre && (
        <NeumaticoMotoMobile
          selectorMotoState={selectorMotoState}
          actual={medidaActual as MedidaName}
        />
      )}
      <div className={styles.title}>{title}</div>
      {!selectable || !selectable.options || selectable.loading ? (
        <LoadingOptions />
      ) : (
        <form className={styles.options_container}>
          <div className={styles.subtitle}>{subtitle}</div>
          <ul className={styles.option_list_container}>
            {selectable.options &&
              selectable.options
                .slice(0, NUMBER_FOR_PAGE)
                .map((option, index) => (
                  <li
                    className={styles.option_list_item}
                    onClick={() => onHandleClick(option.value)}
                    key={index}>
                    {option.label}
                  </li>
                ))}
          </ul>

          {showMore && (
            <React.Fragment>
              <ul className={styles.option_list_container}>
                {selectable.options &&
                  selectable.options
                    .slice(NUMBER_FOR_PAGE)
                    .map((option, index) => (
                      <li
                        className={styles.option_list_item}
                        onClick={() => onHandleClick(option.value)}
                        key={index}>
                        {option.label}
                      </li>
                    ))}
              </ul>
              <div onClick={toggleShowMore} className={styles.mostrar_menos}>
                {mostrarMenos}
              </div>
            </React.Fragment>
          )}
          {!showMore && selectable.options.length > NUMBER_FOR_PAGE && (
            <div onClick={toggleShowMore} className={styles.mas_medidas}>
              <FontAwesomeIcon
                className={styles.mas_medidas_icon}
                icon={faPlusCircle}
              />
              {mostrarMas}
            </div>
          )}
        </form>
      )}
    </div>
  )
}

export default SelectorMedidasMobile
