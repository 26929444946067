import React from 'react'
import { t } from '../../../../../src/common/i18n'
import FunnelSideBar from '../../../../../src/common/components/funnelSideBar/FunnelSideBar'
import MainFormContainer from '../../../../../src/common/components/layouts/mainFormContainer/MainFormContainer'
import LeadFormContainer from '../Common/LeadFormContainer'
import SelectorFooterDesktop from '../Footers/SelectorFooterDesktop'
import { FunnelMotoScreenProps, StepsInDesktop } from '../SelectorMotoSteps'
import LayoutSelectorMotoDesktop from '../../../../../src/common/components/layouts/layoutSelectorMoto/LayoutSelectorMotoDesktop'

type Props = FunnelMotoScreenProps
const FormTusDatos = ({
  dispatch,
  nextStep,
  previousStep,
  selectorMotoState,
}: Props) => {
  return (
    <LayoutSelectorMotoDesktop
      stepActive={StepsInDesktop.INSERT_DATOS_LEAD}
      title={t('preferences.cambio_neumaticos')}
      subTitle={t('preferences.tus_preferencias')}>
      <MainFormContainer>
        <LeadFormContainer
          dispatch={dispatch}
          selectorMotoState={selectorMotoState}
          previousStep={previousStep}
          nextStep={nextStep}
          footer={(props) => (
            <SelectorFooterDesktop
              onGoBack={previousStep}
              compareButton
              {...props}
            />
          )}
        />
      </MainFormContainer>
      <FunnelSideBar />
    </LayoutSelectorMotoDesktop>
  )
}

export default FormTusDatos
