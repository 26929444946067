import React, { useContext } from 'react'
import AppContext from '../../../../../../src/common/context/context'
import { setErrorMedidaMoto } from '../../../../context/actions/selectorMotoActions'
import { t } from '../../../../i18n'
import styles from './index.module.scss'
import StandardModal from '../../../../../../src/common/components/modal/standardModal/StandardModal'

export const ModalErrorMedidas = () => {
  const { selectorMotoState, dispatch } = useContext(AppContext)

  const onCloseErrorModal = () => {
    dispatch(setErrorMedidaMoto(false))
  }

  return (
    <StandardModal
      closeModal={onCloseErrorModal}
      showModal={selectorMotoState.error_search_medidas_moto}
      title={t('resultados_neumaticos.no_resultados')}
      id="selector-modal-error">
      <section className={styles.sections}>
        <p>{t('resultados_neumaticos.error_get_medidas_moto')}</p>
      </section>
    </StandardModal>
  )
}
