import React from 'react'
import {
  FunnelMotoScreenProps,
  StepsFeaturesInMobile,
} from '../SelectorMotoSteps'
import SelectorContainerMotoMobile from '../Common/SelectorContainerMotoMobile'

type Props = FunnelMotoScreenProps
const CilindradaModelo = (props: Props) => {
  return (
    <SelectorContainerMotoMobile
      {...props}
      stepActive={StepsFeaturesInMobile.SELECT_CILINDRADA_MODELO}
      medidaActual={'cilindrada'}
    />
  )
}

export default CilindradaModelo
