import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../../../../../src/common/context/context'
import { buscarPorMedidas } from '../../../context/actions/selectorMotoActions'
import { validateBuscarPorMedidas } from '../../../context/reducers/selectorMotoReducer'
import NeumaticoHomeMoto from '../../../../../src/common/assets/home/neumatico_home_moto.svg'
import styles from '../Results/selectorModal.module.scss'
import MotoIcon from '../../../../../src/common/assets/images/family_icons/icon_moto.svg'
import SelectorContainerMoto from './SelectorContainerMoto'
import SelectorModeloMotoDesktop from '../Modelo/SelectorModeloMotoDesktop'
import { t } from '../../../i18n'
import SelectorMotoDesktop from '../Desktop/SelectorMotoDesktop'

interface Props {
  showMarcas?: boolean
  handleSubmit?: (event: React.FormEvent<HTMLFormElement>) => void
}

const ContainerEditSelectorMoto = ({ handleSubmit, showMarcas }: Props) => {
  const [tabActive, setTabActive] = useState(0)
  const { dispatch, selectorMotoState } = useContext(AppContext)

  useEffect(() => {
    setTabActive(selectorMotoState.buscar_por_medidas ? 0 : 1)
  }, [])

  const onSubmitMedidas = async (event) => {
    event.preventDefault()
    if (validateBuscarPorMedidas(selectorMotoState)) {
      await buscarPorMedidas(selectorMotoState, dispatch)
      handleSubmit(event)
    }
  }

  const onSubmitModelo = async () => {
    handleSubmit(null)
  }

  const tabsArray = [
    {
      id: 0,
      icon: <NeumaticoHomeMoto className={styles.icon_neumatico} />,
      title: 'selector-moto.buscar_por_medida',
    },
    {
      id: 1,
      icon: <MotoIcon className={styles.icon_moto} />,
      title: 'selector-moto.buscar_por_moto',
    },
  ]

  return (
    <div className={styles.tabs_home_wrapper}>
      <ul className={styles.tabrow}>
        {tabsArray.map((tab) => {
          return (
            <li
              key={tab.id}
              onClick={() => setTabActive(tab.id)}
              className={
                tabActive === tab.id
                  ? `${styles.tab} ${styles.selected}`
                  : styles.tab
              }>
              <div className={styles.tab_title}>
                {tab.icon}
                {t(tab.title)}
              </div>
            </li>
          )
        })}
      </ul>
      <div className={styles.content}>
        {tabActive === 0 && (
          <SelectorContainerMoto showMarcas={showMarcas}>
            {({ ...props }) => (
              <SelectorMotoDesktop {...props} handleSubmit={onSubmitMedidas} />
            )}
          </SelectorContainerMoto>
        )}
        {tabActive === 1 && (
          <div className={styles.center}>
            <SelectorModeloMotoDesktop onSubmit={onSubmitModelo} />
          </div>
        )}
      </div>
    </div>
  )
}
export default ContainerEditSelectorMoto
