import React from 'react'
import { FunnelMotoScreenProps, StepsInMobile } from '../SelectorMotoSteps'
import SelectorContainerMotoMobile from '../Common/SelectorContainerMotoMobile'

type Props = FunnelMotoScreenProps
const FormAlto = (props: Props) => {
  return (
    <SelectorContainerMotoMobile
      {...props}
      stepActive={StepsInMobile.SELECT_ALTO}
      medidaActual={'alto'}
    />
  )
}

export default FormAlto
