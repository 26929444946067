import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './modalHelp.module.scss'
import Modal from '../../../../../../src/common/components/modal/Modal'
import PhoneNumberLink from '../../../../../../src/common/components/PhoneNumberLink/phonenumberlink'
import telephones from '../../../../../../src/specific/constants/telephones'
import React from 'react'
import { t } from '../../../../i18n'

interface Props {
  showModalPhone: boolean
  onCloseModal: () => void
}

const ModalHelp = ({ showModalPhone, onCloseModal }: Props) => {
  return (
    <Modal showModal={showModalPhone} closeModal={onCloseModal}>
      <div className={styles.modal_title}>{t('preferences.te_ayudamos')}</div>
      <div className={styles.modal_item}>
        <div className={styles.icon_button_container}>
          <FontAwesomeIcon icon={faMobileAlt} className={styles.icon_button} />
        </div>
        <div className={styles.modal_text}>
          {telephones.map((elem) => (
            <div key={elem.id}>
              <PhoneNumberLink
                telephone={elem.telephone}
                location={elem.location}
              />
            </div>
          ))}
          {t('funnel.tel_gratis')}
          {t('preferences.horario1')}
          {/* Quitar horario de sabados, se deja por si luego quieren recuperarlo
          <FormattedMessage id="preferences.horario2" />
          */}
        </div>
      </div>
    </Modal>
  )
}

export default ModalHelp
