import React from 'react'
import { t } from '../../../../i18n'
import styles from './loadingOptions.module.scss'
import SpinnerWithText from '../../../../../../src/common/components/spinnerWithText/SpinnerWithText'

const LoadingOptions = () => (
  <div className={styles.loading}>
    <SpinnerWithText text={t('selector.cargando')} loading />
  </div>
)

export default LoadingOptions
