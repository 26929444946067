import React from 'react'
import Button from '../../../../src/common/components/buttons/Button'
import styles from './camaraMotoResultsCard.module.scss'
import { CamaraMotoResult } from '../../api/searchCamaraMoto'
import { intl, t, textWithLocale } from '../../i18n'
interface LogoProps {
  slug: string | null
}
const Logo = ({ slug }: LogoProps) => {
  let Image
  try {
    Image = require(`../../../../src/common/assets/images/logo_marcas/${slug}.svg`)
  } catch (e) {
    Image = require(`../../../../src/common/assets/images/logo_marcas/no-marca.svg`)
  }
  return <Image className={styles.marca}></Image>
}
const CardMobile = ({ camara, onSelectCamara }: Props) => {
  return (
    <div className={styles.neumatico_results_card_mobile}>
      <div className={styles.content_top}>
        <div className={styles.neumatico}>
          <div className={styles.logoMarcaMobile}>
            <Logo slug={camara.logo} />
          </div>
          <div
            className={styles.image}
            style={{
              backgroundImage: `url('${camara.urlImagen}')`,
            }}
          />
        </div>
        <div className={styles.description}>
          <div className={styles.marca}>
            {camara[textWithLocale('nombre_producto')]}
          </div>
          <div className={styles.characteristics}>
            {camara[textWithLocale('descripcion')]}
          </div>
        </div>
        <div className={styles.prices}>
          <div className={styles.price}>
            {intl.formatNumber(camara.precio, {
              style: 'currency',
              currency: 'EUR',
            })}
          </div>
        </div>
      </div>
      <div className={styles.content_bottom}>
        <Button
          buttonClass="rodi_button_mobile"
          onClick={() => onSelectCamara(camara)}>
          {t('resultados_neumaticos.elegir')}
        </Button>
      </div>
    </div>
  )
}

const CardDesktop = ({ camara, onSelectCamara }: Props) => {
  return (
    <div className={styles.neumatico_results_card}>
      <div className={styles.images}>
        <div className={styles.logoMarca}>
          <Logo slug={camara.logo} />
        </div>
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${camara.urlImagen})`,
            backgroundSize: 'contain',
          }}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.marca}>
          {camara[textWithLocale('nombre_producto')]}
        </div>
        {camara[textWithLocale('descripcion')]}
      </div>
      <div className={styles.actions}>
        <div className={styles.prices}>
          <div className={styles.price}>
            {intl.formatNumber(camara.precio, {
              style: 'currency',
              currency: 'EUR',
            })}
          </div>
        </div>
        <Button
          buttonClass="rodi_button"
          onClick={() => onSelectCamara(camara)}>
          {t('resultados_neumaticos.elegir')}
        </Button>
      </div>
    </div>
  )
}

interface Props {
  camara: CamaraMotoResult
  onSelectCamara: (camara: CamaraMotoResult) => void
}
export default function CamaraMotoResultsCard({ ...props }: Props) {
  return (
    <React.Fragment>
      <CardMobile {...props} />
      <CardDesktop {...props} />
    </React.Fragment>
  )
}
