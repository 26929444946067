import styles from './SelectorModeloMotoDesktop.module.scss'
import React, { useContext, useEffect, useRef } from 'react'
import Button from '../../../../../src/common/components/buttons/Button'
import Next from '../../../../../src/common/assets/images/Talleres/next.svg'
import AppContext from '../../../../../src/common/context/context'
import {
  buscarMedidasTyres,
  buscarPorModelo,
  resetFeaturesModelo,
  selectFeatureModeloMoto,
} from '../../../context/actions/selectorMotoActions'
import RadioButtonYellow from '../../../../../src/common/components/radioButtonYellow/RadioButtonYellow'
import { validateBuscarPorModelo } from '../../../context/reducers/selectorMotoReducer'
import { t } from '../../../i18n'
import InputDropDown from '../../../../shared/components/Input/InputDropDown'
import useMarcasPrincipales from '../../../../moto/revisiones/components/datos/moto/marca/useMarcasPrincipales'
import { OptionGrouped } from '../../../../shared/components/DropDown/DropDown'

interface SelectorModeloProps {
  onSubmit: () => void
}

const SelectorModeloMotoDesktop = ({ onSubmit }: SelectorModeloProps) => {
  const { selectorMotoState, dispatch } = useContext(AppContext)
  const { selectorModelo } = selectorMotoState

  const cilindradaRef = useRef(null)
  const modeloRef = useRef(null)
  const fabricacionRef = useRef(null)

  const onChangeFeature = (event, name) => {
    if (event) {
      dispatch(
        selectFeatureModeloMoto({
          name,
          value: event.value,
        })
      )
    }
  }
  const onChangeAplication = (value: string) => {
    dispatch(
      selectFeatureModeloMoto({
        name: 'aplicacion',
        value,
      })
    )
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    if (validateBuscarPorModelo(selectorMotoState)) {
      await buscarPorModelo(selectorMotoState, dispatch)
      if (await buscarMedidasTyres(selectorMotoState, dispatch)) {
        onSubmit()
      }
    }
  }

  useEffect(() => {
    if (!selectorModelo.marca.options.length) {
      dispatch(resetFeaturesModelo())
    }
  }, [])

  useEffect(() => {
    if (modeloRef.current && !selectorModelo.modelo.value) {
      modeloRef.current.select.clearValue()
    }
    if (cilindradaRef.current && !selectorModelo.cilindrada.value) {
      cilindradaRef.current.select.clearValue()
    }
    if (fabricacionRef.current && !selectorModelo.fabricacion.value) {
      fabricacionRef.current.select.clearValue()
    }
  }, [selectorModelo])

  const { options: marcas } = useMarcasPrincipales({
    marcas: selectorModelo.marca.options,
    loading: selectorModelo.marca.loading,
    error: null,
  })

  const cilindradas: OptionGrouped[] = [
    {
      label: '',
      options: selectorModelo.cilindrada.options,
    },
  ]

  const sortedCilindradas = cilindradas.map((option) => {
    if ('options' in option) {
      // This is an OptionGrouped object
      const sortedOptions = option.options.sort((a, b) => {
        return parseInt(a.value.toString()) - parseInt(b.value.toString())
      })
      return {
        ...option,
        options: sortedOptions,
      }
    } else {
      // This is a DropDownOption object
      return option
    }
  })

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.selector_home_container}>
        <div className={styles.neumatico_wrapper}>
          <div className={styles.row}>
            <div className={`${styles.dropdown} ${styles.dropdown_m}`}>
              <InputDropDown
                size={'xl'}
                disabled={selectorModelo.marca.options.length === 0}
                placeholder={t('revision_moto.selecciona_marca')}
                labelId="selector-moto.marca"
                name="marca"
                options={marcas}
                onChange={(e) => onChangeFeature(e, 'marca')}
                labelAbove={true}
                required
                searchable={true}
                errors={{ marca: null }}
              />
            </div>
            <div className={`${styles.dropdown} ${styles.dropdown_m}`}>
              <InputDropDown
                formRef={cilindradaRef}
                size={'xl'}
                disabled={selectorModelo.cilindrada.options.length === 0}
                placeholder={t('revision_moto.selecciona_cilindrada')}
                labelId="selector-moto.cilindrada"
                name="cilindrada"
                options={sortedCilindradas}
                onChange={(e) => onChangeFeature(e, 'cilindrada')}
                labelAbove={true}
                required
                searchable={true}
                errors={{ cilindrada: null }}
              />
            </div>
            <div className={`${styles.dropdown} ${styles.dropdown_m}`}>
              <InputDropDown
                formRef={modeloRef}
                size={'xl'}
                disabled={selectorModelo.modelo.options.length === 0}
                placeholder={t('revision_moto.selecciona_modelo')}
                labelId="selector-moto.modelo"
                name="modelo"
                options={selectorModelo.modelo.options}
                onChange={(e) => onChangeFeature(e, 'modelo')}
                labelAbove={true}
                required
                searchable={true}
                errors={{ modelo: null }}
              />
            </div>
            <div className={`${styles.dropdown} ${styles.dropdown_m}`}>
              <InputDropDown
                formRef={fabricacionRef}
                disabled={selectorModelo.fabricacion.options.length === 0}
                placeholder={t('revision_moto.selecciona_ano')}
                labelId="selector-moto.fabricacion"
                name="fabricacion"
                options={selectorModelo.fabricacion.options?.sort((a, b) =>
                  b.label.toString().localeCompare(a.label.toString())
                )}
                onChange={(e) => onChangeFeature(e, 'fabricacion')}
                labelAbove={true}
                errors={{ fabricacion: null }}
              />
            </div>
            <div className={`${styles.dropdown} ${styles.optionsList}`}>
              {selectorModelo.aplicacion.options.map((ap) => {
                return (
                  <RadioButtonYellow
                    key={ap.value}
                    onChange={onChangeAplication}
                    checked={selectorModelo.aplicacion.value === ap.value}
                    label1={
                      <div
                        className={`${styles.label_title} ${styles.label_sm}`}>
                        {ap.label}
                      </div>
                    }
                    name="aplicacion"
                    value={ap.value}
                  />
                )
              })}
            </div>
          </div>
        </div>
        <div className={styles.button_wrapper}>
          <Button
            disabled={!validateBuscarPorModelo(selectorMotoState)}
            buttonClass="next_button"
            onClick={handleSubmit}>
            {t('selector-desktop.buscar')}
            <Next />
          </Button>
        </div>
      </div>
    </form>
  )
}

export default SelectorModeloMotoDesktop
