import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styles from './selectorFooterMobile.module.scss'
import ModalHelp from '../Common/Modals/ModalHelp'

interface Props {
  step: number
}

const SelectorMotoFooterMobile = ({ step }: Props) => {
  const [showModalPhone, setShowModalPhone] = useState(false)
  const togglePhone = () => {
    setShowModalPhone(!showModalPhone)
  }
  return (
    <div
      className={
        step === 0
          ? styles.selector_footer_container
          : styles.selector_footer_container_ayuda
      }>
      <div onClick={togglePhone} className={styles.ayuda}>
        <FontAwesomeIcon
          className={styles.ayuda_icon}
          icon={faQuestionCircle}
        />
        <FormattedMessage id="selector-mobile.ayuda" />
      </div>
      <ModalHelp showModalPhone={showModalPhone} onCloseModal={togglePhone} />
    </div>
  )
}

export default SelectorMotoFooterMobile
