import React from 'react'
import FunnelSideBar from '../../../../../src/common/components/funnelSideBar/FunnelSideBar'
import { t } from '../../../i18n'
import { LayoutSelectorDesktop } from '../../layouts/layoutSelector/LayoutSelector'
import MainFormContainer from '../../../../../src/common/components/layouts/mainFormContainer/MainFormContainer'
import SelectorContainerMoto from '../Common/SelectorContainerMoto'
import SelectorMedidas from '../Desktop/SelectorMedidas'
import { navigate } from 'gatsby'
import Step, { StepDetails } from '../SelectorMotoSteps'

interface Props {
  path: string
}

const Medidas = ({ path }: Props) => {
  const onSubmit = async () => {
    await navigate(StepDetails[Step.SELECT_PREFERENCIAS].routes.desktop)
  }
  return (
    <LayoutSelectorDesktop
      hideSelectorSteps={false}
      stepActive={1}
      title={t('medidas.title')}
      subTitle={t('medidas.subTitle')}>
      <MainFormContainer>
        <SelectorContainerMoto
          showMarcas={false}
          onSumbitMedidas={onSubmit}
          origin={path}>
          {({ ...props }) => <SelectorMedidas {...props} />}
        </SelectorContainerMoto>
      </MainFormContainer>
      <FunnelSideBar />
    </LayoutSelectorDesktop>
  )
}

export default Medidas
