import styles from '../DBFilters.module.scss'
import CheckBoxYellow from '../../../../../../../src/common/components/checkboxYellow/CheckBoxYellow'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { OptionMedida } from '../../../../../api/selectorMoto'
import { t } from '../../../../../i18n'

interface Props {
  options: OptionMedida[]
  name: string
  includes: string[]
  mostrar_mas: string
  changeValue: (name, value, checked) => void
}

const FilterComponent = ({
  options,
  name,
  changeValue,
  includes,
  mostrar_mas,
}: Props) => {
  const [showMore, setShowMore] = useState(
    options
      .slice(6)
      .some((option) =>
        includes.find((include) => include === option.value.toString())
      )
  )
  const toggleShowMore = () => {
    setShowMore(!showMore)
  }

  const onChangeValue = (event) => {
    const { checked, value } = event.target
    changeValue(name, value, checked)
  }

  return (
    <React.Fragment>
      <ul className={styles.marcas}>
        {options.slice(0, 6).map((option) => (
          <li key={option.value}>
            <CheckBoxYellow
              name={option.label}
              value={option.value}
              onChange={onChangeValue}
              checked={includes.includes(option.value.toString())}
              size="small"
              label1={<div className={styles.label}>{option.label}</div>}
            />
          </li>
        ))}

        {showMore &&
          options.slice(6).map((option) => (
            <li key={option.value}>
              <CheckBoxYellow
                name={option.label}
                value={option.value}
                onChange={onChangeValue}
                checked={includes.includes(option.value.toString())}
                size="small"
                label1={<div className={styles.label}>{option.label}</div>}
              />
            </li>
          ))}
      </ul>

      {showMore ? (
        <div onClick={toggleShowMore} className={styles.toggle_medidas}>
          {t('selector-mobile.menos_medidas')}
        </div>
      ) : (
        options.length > 6 && (
          <div onClick={toggleShowMore} className={styles.toggle_medidas}>
            <FontAwesomeIcon
              className={styles.toggle_medidas_icon}
              icon={faPlusCircle}
            />
            {t(mostrar_mas)}
          </div>
        )
      )}
    </React.Fragment>
  )
}

export default FilterComponent
