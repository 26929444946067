import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { reset } from '../../../context/actions/selectorMotoActions'
import { IAppState } from '../../../context/context'
import { selectMedidasMoto } from '../../../context/selectors/selectorMotoSelectors'
import { t } from '../../../i18n'
import Size from '../../../../../src/common/utils/media-queries-setup'
import FunnelSideBar from '../../../../../src/common/components/funnelSideBar/FunnelSideBar'
import Step, { FunnelMotoScreenProps, StepDetails } from '../SelectorMotoSteps'
import { SearchResultsContent } from '../Results/searchResultContent'
import LayoutSelectorMoto from '../../../../../src/common/components/layouts/layoutSelectorMoto/LayoutSelectorMoto'

interface Props extends FunnelMotoScreenProps {
  appState: IAppState
  page: 'resultados' | 'camaras'
}

const SearchResults = ({
  dispatch,
  appState,
  selectorMotoState,
  previousStep,
  nextStep,
  page,
}: Props) => {
  const [isSelectorModalOpen, setSelectorModalOpen] = useState(false)

  const subTitle = ` ${t('selector.para')} ${
    !selectorMotoState.ancho.value ? '' : selectorMotoState.ancho.value
  } ${!selectorMotoState.alto.value ? '' : '/'} ${
    !selectorMotoState.alto.value ? '' : selectorMotoState.alto.value
  } ${!selectorMotoState.llanta.value ? '' : selectorMotoState.llanta.value}`

  return (
    <LayoutSelectorMoto
      title={t('preferences.cambio_neumaticos')}
      subTitle={t('steps.resultados')}
      subTitle2={subTitle}
      stepActive={4}
      showButtonEdit={true}
      showTabs={false}
      hideSelectorSteps={true}
      onClickButtonEditMobile={() => {
        navigate(StepDetails[Step.INITIAL].routes.mobile)
        dispatch(
          reset({
            keepValues: true,
            keepMedidas: selectMedidasMoto(appState),
            marca: null,
            origen: selectorMotoState.origen,
          })
        )
      }}
      onClickButtonEditDesktop={() => setSelectorModalOpen(true)}>
      <SearchResultsContent
        dispatch={dispatch}
        previousStep={previousStep}
        nextStep={nextStep}
        showSelectorModal={isSelectorModalOpen}
        closeSelectorModal={() => {
          setSelectorModalOpen(false)
        }}
        page={page}
      />
      <Size.Matcher desktop={<FunnelSideBar />} tablet={null} mobile={null} />
    </LayoutSelectorMoto>
  )
}

export default connect((state: IAppState) => ({ appState: state }))(
  SearchResults
)
