import React from 'react'
import { FunnelMotoScreenProps, StepsInMobile } from '../SelectorMotoSteps'
import SelectorContainerMotoMobile from '../Common/SelectorContainerMotoMobile'

type Props = FunnelMotoScreenProps
const FormLlanta = (props: Props) => {
  return (
    <SelectorContainerMotoMobile
      {...props}
      stepActive={StepsInMobile.SELECT_LLANTA}
      medidaActual={'llanta'}
    />
  )
}

export default FormLlanta
