import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Next from '../../../../../src/common/assets/images/Talleres/next.svg'
import Button from '../../../../../src/common/components/buttons/Button'
import { t } from '../../../i18n'
import Size from '../../../../../src/common/utils/media-queries-setup'
import styles from './selectorFooterDesktop.module.scss'

interface Props {
  children?: string | JSX.Element[] | JSX.Element
  onGoBack?: () => void
  formState?: {
    isSubmitting: boolean
  }
  nextButtonContent?: string
  onClick?: () => void
  continueButton?: boolean
  searchButton?: boolean
  compareButton?: boolean
  count?: number
}

export default function SelectorFooterDesktop({
  children,
  onGoBack,
  formState,
  nextButtonContent,
  onClick,
  continueButton,
  searchButton,
  compareButton,
  count,
}: Props) {
  return (
    <div className={styles.SelectorFooterDesktop_wrapper}>
      <div
        className={
          onGoBack ? styles.container : styles.container_withoutGoBack
        }>
        {onGoBack && (
          <a onClick={onGoBack} className={styles.go_back}>
            <FontAwesomeIcon
              icon={faChevronLeft}
              className={styles.go_back_icon}
            />
            {t('cesta.general.volver_atras')}
          </a>
        )}
        {children}
        {continueButton && (
          <Button
            onClick={onClick}
            disabled={formState && formState.isSubmitting}
            type="submit"
            buttonClass="next_button">
            {t('selector-desktop.continuar')}
            <Next />
          </Button>
        )}

        {searchButton && (
          <Button
            onClick={onClick}
            disabled={formState && formState.isSubmitting}
            type="submit"
            buttonClass="next_button">
            {nextButtonContent || (
              <React.Fragment>
                {t('preferences.buscar_neumaticos')}
                <Next />
              </React.Fragment>
            )}
          </Button>
        )}

        {compareButton && (
          <Size.Matcher
            mobile={
              <Button
                onClick={onClick}
                disabled={formState && formState.isSubmitting}
                type="submit"
                buttonClass="rodi_full_width">
                {t('pre_register.comparar_neumaticos', { count })}
              </Button>
            }
            desktop={
              <Button
                onClick={onClick}
                disabled={formState && formState.isSubmitting}
                type="submit"
                buttonClass="next_button">
                {t('pre_register.comparar_neumaticos', { count })}
              </Button>
            }
          />
        )}
      </div>
    </div>
  )
}
