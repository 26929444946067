import React from 'react'
import { FunnelMotoScreenProps } from '../SelectorMotoSteps'
import styles from './ContenedorPreferencias.module.scss'
import SelectorMarcas from './preferencias/SelectorMarcas'
import SelectorFooterDesktop from '../Footers/SelectorFooterDesktop'
import { navigate } from '@reach/router'
import { t } from '../../../i18n'

type Props = FunnelMotoScreenProps
const ContenedorPreferencias = ({ selectorMotoState, nextStep }: Props) => {
  const handleSubmit = (event) => {
    event.preventDefault()
    nextStep()
  }
  return (
    <div>
      <section className={styles.sections}>
        <form onSubmit={handleSubmit}>
          <div className={styles.caracteristicas_wrapper}>
            <div className={styles.title}>
              {t('preferences.neumatico_interesa')}{' '}
            </div>
            <SelectorMarcas selectorMotoState={selectorMotoState} />
          </div>
          <SelectorFooterDesktop
            onGoBack={() => {
              navigate('/neumaticos/moto/')
            }}
            searchButton
          />
        </form>
      </section>
    </div>
  )
}

export default ContenedorPreferencias
