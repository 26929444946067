import { ListaMarcas } from '../../../context/reducers/selectorReducer'
import styles from './MarcasMotoList.module.scss'
import { sortBy } from 'lodash'
import RadioButtonYellow from '../../../../../src/common/components/radioButtonYellow/RadioButtonYellow'
import React, { useContext } from 'react'
import AppContext from '../../../../../src/common/context/context'

interface Props {
  selectedMarca: null | number
  marcasList: ListaMarcas
  onMarcaSelect: (marca: number) => void
}

const MarcasMotoList = ({
  selectedMarca,
  marcasList,
  onMarcaSelect,
}: Props) => {
  const { selectorMotoState } = useContext(AppContext)
  const marcas = sortBy(
    marcasList.filter((m) => {
      return m.moto
    }),
    ['orden']
  )
  return (
    <div className={styles.marcas_list_wrapper}>
      <div className={styles.marcas_wrapper}>
        {marcas.map((item, index) => {
          const disabled = !selectorMotoState.filterables.marcas.some(
            (marca) => {
              return parseInt(marca.value) === item.value
            }
          )
          const label = (
            <div
              className={`${disabled ? styles.disabled : styles.label_title}`}>
              {item.label}
            </div>
          )
          return (
            <div key={index} className={styles.marca}>
              <RadioButtonYellow
                checked={selectedMarca === item.value}
                onChange={onMarcaSelect}
                disabled={disabled}
                label1={label}
                name="caracteristicas-radio"
                value={item.value}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default MarcasMotoList
