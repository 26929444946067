import {
  faChevronDown,
  faChevronUp,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useState } from 'react'
import AppContext from '../../../../../../src/common/context/context'
import { t } from '../../../../i18n'
import styles from './DBFilters.module.scss'
import FilterComponent from './Filters/FilterComponent'
import { changeFiltro } from '../../../../context/actions/selectorMotoActions'
import Size from '../../../../../../src/common/utils/media-queries-setup'

interface SectionProps {
  index: number
  current: number
  title: string
  toggle: (index: number) => void
  children: any
  className?: string
}

const Section = ({
  index,
  current,
  title,
  toggle,
  children,
  className,
}: SectionProps) => {
  const open = index === current
  const classes = [styles.section]
  if (open) {
    classes.push(styles.open)
  }
  if (className) {
    classes.push(className)
  }

  return (
    <div className={classes.join(' ')}>
      <div
        className={styles.section_title}
        onClick={() => {
          toggle(open ? 0 : index)
        }}>
        {title}
        {open ? (
          <FontAwesomeIcon icon={faChevronUp} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </div>

      <div className={styles.section_content}>{children}</div>
    </div>
  )
}

const DBFilters = () => {
  const { selectorMotoState, searchMotoState, dispatch } =
    useContext(AppContext)
  const [filterOpen, setFilterOpen] = useState(0)

  const device = Size.useMedia({
    mobile: 'MOBILE',
    desktop: 'DESKTOP',
  }) as 'MOBILE' | 'TABLET' | 'DESKTOP'

  const [expanded, setExpanded] = useState(
    device !== 'MOBILE' && !!selectorMotoState.filtros.marcas.length
  )
  const handleOnClick = () => {
    setExpanded(!expanded)
  }

  const handleOnClickFilter = (n_filter: number) => {
    setFilterOpen(n_filter)
  }

  const onChangeValue = (name: string, value: string, checked: boolean) => {
    dispatch(changeFiltro(name, value, checked))
  }

  return (
    <div className={styles.filtros}>
      <div className={styles.main_title} onClick={handleOnClick}>
        {t('resultados_neumaticos.filtrar_gama')}
        {expanded ? (
          <FontAwesomeIcon icon={faTimes} />
        ) : (
          <FontAwesomeIcon icon={faChevronDown} />
        )}
      </div>
      {expanded && (
        <div className={styles.container}>
          <Section
            index={1}
            current={filterOpen}
            title={t('ficha-marca.marcas')}
            toggle={handleOnClickFilter}
            className={styles.marcas_container}>
            <FilterComponent
              options={selectorMotoState.filterables.marcas}
              changeValue={onChangeValue}
              name={'marcas'}
              includes={selectorMotoState.filtros.marcas}
              mostrar_mas={'selector-mobile.mas_marcas'}
            />
          </Section>
          <Section
            index={2}
            current={filterOpen}
            title={t('filtros.gama')}
            toggle={handleOnClickFilter}
            className={styles.marcas_container}>
            <FilterComponent
              options={selectorMotoState.filterables.gamas}
              changeValue={onChangeValue}
              name={'gamas'}
              includes={selectorMotoState.filtros.gamas}
              mostrar_mas={'filtros.mas_gamas'}
            />
          </Section>
          {!!searchMotoState.results.tipos.length && (
            <Section
              index={3}
              current={filterOpen}
              title={t('filtros.tipos')}
              toggle={handleOnClickFilter}
              className={styles.marcas_container}>
              <FilterComponent
                options={searchMotoState.results.tipos}
                changeValue={onChangeValue}
                name={'tipos'}
                includes={selectorMotoState.filtros.tipos}
                mostrar_mas={'filtros.mas_tipos'}
              />
            </Section>
          )}
        </div>
      )}
    </div>
  )
}

export default DBFilters
